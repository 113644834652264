import { createContext, useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useQueryStringParams } from "../queryStringParams";
import { GetCompanyCategories } from "../queries/GetCompanyCategories";
import LoadingSpinner from "../components/spinner/LoadingSpinner";

import { ShuffleArray } from "../tools";
import { REACT_APP_API_URL } from "../constants";

//create a pagecontext with the createContext hook
export const CompanyCategoryContext = createContext({});

export default function CompanyCategoryProvider(props) {
  //get query string params
  const qsParams = useQueryStringParams();

  let myVariables = {
    parentSlug: qsParams.get("type")?.toLowerCase(),
    categorySlugs: qsParams.get("cat")?.toLowerCase().split("_"),
    search: qsParams.get("search")?.toLowerCase(),
    isToday: qsParams.get("filter")?.toLowerCase().includes("vandaag") ? true : null,
    isKoopavond: qsParams.get("filter")?.toLowerCase().includes("koopavond") ? true : null,
    currentPage: qsParams.get("p") ? parseInt(qsParams.get("p")) : 1,
    offset: 0,
    size: 24
  }
  myVariables.offset = (myVariables.currentPage - 1) * myVariables.size;

  //get companies
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [companies, setCompanies] = useState(null);

  useEffect(() => {
    fetch(REACT_APP_API_URL + '/wp-content/uploads/companies.json')
      .then(response => response.json())
      .then((json) => {
        setLoading(false);
        setCompanies(shuffleCompanies(json.data.companies));
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  //shuffle companies
  function shuffleCompanies(companies) {
    let shuffledCompanies = JSON.parse(JSON.stringify(companies));

    ShuffleArray(shuffledCompanies.nodes);

    return shuffledCompanies;
  }

  //get categories
  const { loading: loading2, error: error2, data: data2 } = useQuery(GetCompanyCategories, {
    variables: {
      search: myVariables.search
    }
  });

  if (loading || loading2) return <LoadingSpinner />;
  if (error || error2) return <div>Error</div>;

  if (!companies) return null;

  //filtering on cloned json
  let filteredCompanies = JSON.parse(JSON.stringify(companies));

  //filter on parent slug
  if (myVariables.parentSlug) {
    filteredCompanies.nodes = filteredCompanies.nodes.filter(company => {
      company.companyCategories.nodes = company.companyCategories.nodes.filter(category => {
        //if has parent categories, this should be the default for every company
        if (category.parentCategories) {
          category.parentCategories.nodes = category.parentCategories.nodes.filter(parentCategory => {
            return parentCategory.slug === myVariables.parentSlug;
          });
          //filter on category slugs or on parent slug
          return myVariables.categorySlugs ? myVariables.categorySlugs.includes(category.slug) : category.parentCategories.nodes.length;
        } else {
          //filter on category slugs or on parent slug
          return myVariables.categorySlugs ? myVariables.categorySlugs.includes(category.slug) : category.slug === myVariables.parentSlug;
        }
      });
      return company.companyCategories.nodes.length;
    });
  }

  //filter on search (the graphql search is LIKE post_title/post_description, but since there is no post_description, check on info_title and info_description)
  if (myVariables.search) {
    filteredCompanies.nodes = filteredCompanies.nodes.filter(company => {
      return company.title.toLowerCase().includes(myVariables.search) || (!company.companySettings.info.hidden && (company.companySettings.info.title?.toLowerCase().includes(myVariables.search) || company.companySettings.info.description?.toLowerCase().includes(myVariables.search)));
    });
  }

  //filter koopavond
  if (myVariables.isKoopavond) {
    filteredCompanies.nodes = filteredCompanies.nodes.filter(company => {
      return company.companySettings.koopavond;
    });
  }

  //filter today
  if (myVariables.isToday) {
    filteredCompanies.nodes = filteredCompanies.nodes.filter(company => {
      let day_of_week = new Date().getDay();

      company.companySettings.businessHours = company.companySettings.businessHours?.filter(obj => {
        return (parseInt(obj.day) || 0) === day_of_week && !obj.closed;
      });

      return company.companySettings.businessHours?.length;
    });
  }

  //set total items
  filteredCompanies.pageInfo.offsetPagination.total = filteredCompanies.nodes.length;

  //set geojson before paging
  const features = filteredCompanies.nodes.filter(company => {
    return company.companySettings.companyData.latitude && company.companySettings.companyData.longitude;
  }).map(company => {
    return (
      {
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [
            company.companySettings.companyData.longitude,
            company.companySettings.companyData.latitude
          ]
        },
        "properties": {
          "id": company.id,
          "title": company.title,
          "address": company.companySettings.companyData.address
        }
      }
    )
  });

  const geojson = {
    "type": "FeatureCollection",
    "features": features
  };

  //paging
  filteredCompanies.nodes = filteredCompanies.nodes.slice((myVariables.currentPage - 1) * myVariables.size, myVariables.currentPage * myVariables.size);



  //create a variable that contains the data from graphql query
  /* let companyListData = data.companies; */
  let companyListData = filteredCompanies;

  //create a variable that contains the data from graphql query, "clone" with json so I can extend it with checked true/false
  const categoryListData = JSON.parse(JSON.stringify(data2.companyCategories));

  //extra filter, can't do it with graphql... (this removes the categories that don't have results)
  for (var i = categoryListData.nodes.length - 1; i >= 0; i--) {
    let companies = categoryListData.nodes[i].children?.nodes

    for (var j = companies.length - 1; j >= 0; j--) {
      if (companies[j].results.pageInfo.offsetPagination.total === 0) {
        companies.splice(j, 1)
      }
    }

    if (companies.length === 0) {
      categoryListData.nodes.splice(i, 1)
    }
  }

  categoryListData.nodes.map((item) => {
    myVariables.parentSlug === item.url ? item["checked"] = true : item["checked"] = false;

    item.children?.nodes.map((subItem) => {
      myVariables.categorySlugs?.includes(subItem.url) ? subItem["checked"] = true : subItem["checked"] = false;

      return subItem;
    })

    return item;
  })

  return (
    <CompanyCategoryContext.Provider value={{ companyListData, categoryListData, myVariables, geojson }}>
      {props.children}
    </CompanyCategoryContext.Provider>
  );
}