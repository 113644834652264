import { useQuery } from "@apollo/client";
import { GetMenu } from "../../queries/GetMenu";
import LoadingSpinner from "../spinner/LoadingSpinner";
import { Link } from "react-router-dom";
import { flatListToHierarchical } from "../../tools";
import logo from '../../../assets/logo_white.svg';
import CustomLink from "../CustomLink";

export default function HeaderMenu() {
  //get menu
  const { loading, error, data } = useQuery(GetMenu, {
    variables: { location: "PRIMARY_MENU" }
  });

  if (loading) return <LoadingSpinner />;
  if (error) return <div>Error</div>;

  //convert the menuItems to a functional hierarchical list
  let menuItems = flatListToHierarchical(data.menuItems.nodes);

  return (
    <>
      <div className="row px-xl-5 px-3 mx-xl-5 pb-5">
        <div className="col">
          <Link to="/">
            <img src={logo} alt="logo" className="d-none d-lg-inline" height="120px" />
            <img src={logo} alt="logo" className="d-lg-none" height="90px" />
          </Link>
        </div>
        <div className="col text-end">
          <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close" />
        </div>
      </div>
      <div className="container pt-lg-5 pt-3 overflow-y-auto">
        <div className="row gy-4 px-xl-5 px-3 mx-xl-5">
          {menuItems.map(item => {
            return (
              <div key={item.key} className="col-md-4 col-sm-6">
                <h3>{item.title}</h3>
                {item.children.length > 0 &&
                  <ul className="list-unstyled">
                    {item.children.map(subItem => {
                      let opts = {};
                      opts['data-bs-dismiss'] = 'offcanvas';

                      return (
                        <li key={subItem.key}>
                          <CustomLink href={subItem.path} opts={opts}>{subItem.title}</CustomLink>
                        </li>
                      )
                    })}
                  </ul>}
              </div>
            )
          })}
        </div>
      </div>
    </>
  );
}
