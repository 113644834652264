import gql from "graphql-tag";

/**
 * get events, optional category filters
 */
export const GetEvents = gql`
query GetEvents($categorySlugs: [String!], $timeUnit: TimeUnitEnum, $dates: [String!], $offset: Int!, $size: Int!) {
  events(where: {eventCategorySlugIn: $categorySlugs, eventTimeUnit: $timeUnit, eventBetweenDates: $dates, offsetPagination: {offset: $offset, size: $size}, orderby: {field: EVENT_START_DATE, order: ASC}}) {
    pageInfo {
      offsetPagination {
        hasMore
        hasPrevious
        total
      }
    }
    nodes {
      id: databaseId
      title
      url: slug
      mediumImage: featuredImage {
        node {
          sourceUrl(size: MEDIUM_LARGE)
        }
      }
      eventSettings {
        beginDate
        endDate
        mustSee
      }
      eventCategories {
        nodes {
          name
          slug
        }
      }
    }
  }
}
`;