import { useContext } from "react";
import { PostContext } from "../providers/PostProvider";
import HeaderBlock from "../components/blocks/headerimage/headerBlock";
import ContentText from "../components/blocks/content/ContentText";
import ContentTextImage from "../components/blocks/content/ContentTextImage";
import useTitle from "../hooks/useTitle";

export default function Post() {
  const { postData } = useContext(PostContext);

  useTitle(postData.title)

  return (
    <>
      <HeaderBlock imageUrl={postData.blockHeader.image?.sourceUrl} title={postData.blockHeader.title} subtitle={postData.blockHeader.displaySubtitle ? postData.blockHeader.subtitle : null} />
      {/* loop through blocks */}
      {postData.blocks.map((item, index) => {
        if (item.blockContentText != null)
          return <ContentText key={index} html={item.blockContentText.editor} />;
        if (item.blockContentTextImage != null)
          return <ContentTextImage key={index} html={item.blockContentTextImage.editor} highlight={item.blockContentTextImage.highlight} displayLeft={item.blockContentTextImage.displayLeft} imageUrl={item.blockContentTextImage.image.sourceUrl} button={item.blockContentTextImage.button} />;
        return null;
      })}
    </>
  );
}