import { useQuery } from "@apollo/client";
import { GetLatestEvents } from "../../../queries/GetLatestEvents";
import LoadingSpinner from "../../spinner/LoadingSpinner";
import EventItemBlock from "../companyCategory/eventItemBlock";
import CustomHtml from "../../CustomHtml";
import CustomLink from "../../CustomLink";
import {GetDateYmd} from "../../../tools";

export default function AgendaSection(props) {
  const { html, button } = props;

  //get latest events
  let date = new Date();

  let firstDay = GetDateYmd(new Date(date.getFullYear(), date.getMonth(), date.getDate()) / 1000);
  let lastDay = GetDateYmd(new Date(date.getFullYear()+1, date.getMonth(), date.getDate()) / 1000);

  let dates = [];
  dates.push(firstDay);
  dates.push(lastDay);

  const { loading, error, data } = useQuery(GetLatestEvents, {
    variables: { quantity: 6, dates:dates }
  });

  if (loading) return <LoadingSpinner />;
  if (error) return <div>Error</div>;

  return (
    <div className="container-fluid bg-gradient-grey-white mb-100 pt-100">
      <div className="container">
        <div className="row text-center">
          <div className="offset-lg-2 col-lg-8 offset-0">
            <div>
              <CustomHtml html={html} />
            </div>
          </div>
        </div>
        {
          //check if nodes exist
          data.events.nodes.length ?

            <div className="row g-xl-5 g-4 py-5">
              {data.events.nodes.map(eventItem => {
                return (
                  <div key={eventItem.id} className="col-lg-4 col-sm-6">
                    <EventItemBlock
                      imageUrl={eventItem.image?.node?.sourceUrl}
                      title={eventItem.title}
                      url={"/evenementen/" + eventItem.url}
                      categoryTitle={eventItem.eventCategories?.nodes[0]?.name}
                      startDate={eventItem.eventSettings.beginDate}
                      endDate={eventItem.eventSettings.endDate}
                      mustSee={eventItem.eventSettings.mustSee}
                    />
                  </div>
                )
              })}
            </div>

            : null
        }

        {button &&
          <div className="row text-center">
            <div className="col-12">
              <CustomLink href={button.url} className="btn btn-primary btn-lg my-4">{button.title}</CustomLink>
            </div>
          </div>
        }
      </div>
    </div>
  );
}