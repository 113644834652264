import { Link } from "react-router-dom";
import logo from '../../../assets/logo_white.svg';
import HeaderMenu from './HeaderMenu';

export default function Header() {
  return (
    <>
      <header className="container-fluid p-lg-5 p-3 pb-0 position-absolute top-0 z-1">
        <div className="row px-xl-5 px-3 mx-xl-5">
          <div className="col">
            <Link to="/">
              <img src={logo} alt="logo" className="d-none d-lg-inline" height="120px" />
              <img src={logo} alt="logo" className="d-lg-none" height="90px" />
            </Link>
          </div>
          <div className="col text-end">
            <div className="btn-menu" data-bs-toggle="offcanvas" data-bs-target="#offcanvasHeader">
              <span></span>
              <span></span>
              <span>Menu</span>
            </div>
          </div>
        </div>
      </header>
      <div id="offcanvasHeader" className="offcanvas offcanvas-top vh-100 container-fluid bg-primary p-lg-5 p-3" data-bs-scroll="false" tabIndex="-1">
        <HeaderMenu />
      </div>
    </>
  );
}