import { useQuery } from "@apollo/client";
import { GetMenu } from "../../queries/GetMenu";
import LoadingSpinner from "../spinner/LoadingSpinner";
import { Link } from "react-router-dom";
import { flatListToHierarchical } from "../../tools";
import LogoColor from "../../../assets/logo_color.svg";
import FooterBottom from "./FooterBottom.";
import NewsletterBlock from "./NewsletterBlock";
import CustomLink from "../CustomLink";

export default function Footer() {
  //get menu
  const { loading, error, data } = useQuery(GetMenu, {
    variables: { location: "FOOTER_MENU" }
  });

  if (loading) return <LoadingSpinner />;
  if (error) return <div>Error</div>;

  //convert the menuItems to a functional hierarchical list
  let menuItems = flatListToHierarchical(data.menuItems.nodes);

  return (
    <>
      <NewsletterBlock />
      <footer className="container-fluid bg-light-grey">
        <div className="container">
          <div className="row gy-4">
            <div className="col-md-3 col-6 mb-4">
              <Link to="/">
                <img src={LogoColor} alt="logo" width="100%" />
              </Link>
            </div>
            {menuItems.map(item => {
              return (
                <div key={item.key} className="col-md-3 col-6">
                  <h4>{item.title}</h4>
                  {item.children.length > 0 &&
                    <ul className="list-unstyled">
                      {item.children.map(subItem => {
                        return (
                          <li key={subItem.key}>
                            {/* <Link to={subItem.path}>{subItem.title}</Link> */}
                            <CustomLink href={subItem.path}>{subItem.title}</CustomLink>
                          </li>
                        )
                      })}
                    </ul>}
                </div>
              )
            })}
          </div>
          <hr />
          <FooterBottom />
        </div>
      </footer>
    </>
  );
}