import { useRef, useEffect } from "react";

export default function HeaderTilesProgressBar(props) {
  const { slickRef, breakpoint, autoplaySpeed } = props;

  //current width
  let width = window.innerWidth;

  //change width on resize
  function handleResize() {
    width = window.innerWidth;
  }
  window.addEventListener('resize', handleResize);

  //progress vars
  const duration = autoplaySpeed;
  const interval = 10;
  let percentTime, step, tick;

  //progress ref
  const progressRef = useRef();

  useEffect(() => {
    let componentIsMounted = true;

    const asyncStart = async () => {
      if (componentIsMounted) {
        startProgressbar();
      }
    };
    asyncStart();

    return () => {
      componentIsMounted = false;

      resetProgressbar();
    }
  });

  //start
  function startProgressbar() {
    resetProgressbar();

    percentTime = 0;
    tick = window.setInterval(increase, interval);
  };

  //increase progress percentage
  function increase() {
    if (progressRef.current !== null && width < breakpoint) {
      step = (duration * 1000) / interval;
      percentTime += 100 / step;
      progressRef.current.style.width = percentTime + "%";

      if (percentTime >= 100) {
        slickRef.current.slickNext();

        startProgressbar();
      }
    }
    else {
      startProgressbar();
    }
  }

  //reset
  function resetProgressbar() {
    if (progressRef.current !== null) {
      progressRef.current.style.width = 0 + '%';
    }

    clearTimeout(tick);
  }

  return (
    <div className="slick-progress d-block d-lg-none position-relative w-100">
      <div className="position-absolute top-0 start-0 h-100 rounded-end bg-primary" ref={progressRef}></div>
    </div>
  );
}