import CustomLink from "../../CustomLink";

export default function CardsSection(props) {
  const { cards } = props;

  return (
    <div className="container-fluid bg-gradient-white-grey mt-100 pb-100">
      <div id="cards" className="container">
        <div className="row g-xl-5 g-lg-4 g-md-3 g-4 text-center">
          {cards.map((item, index) => {
            return (
              <div key={index} className="col-md-4 d-flex align-items-stretch">
                <div className="my-custom-block3 card position-relative z-0">
                  <div className="iconwrapper d-flex rounded-circle mx-auto mb-3">
                    <img src={item.icon.sourceUrl} alt="" className="m-auto" />
                  </div>
                  <div className="card-body d-flex flex-column">
                    <h3>{item.title}</h3>
                    <p className="mb-4">{item.text}</p>
                    <CustomLink href={item.button.url} className="btn btn-primary btn-lg stretched-link mt-auto align-self-center">{item.button.title}</CustomLink>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}
