import { useState } from "react";

const NEWSLETTER_DEFAULTS = {
  newsemail: "",
  newsname: "",
};

export default function useNewsLetter() {
  const [values, setValues] = useState(NEWSLETTER_DEFAULTS);
  const [errors, setErrors] = useState(null);
  const [formSent, setFormSent] = useState(null);

  function onChange(e) {
    setValues({ ...values, [e.target.name]: e.target.value });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    let formBody = [];
    for (let property in values) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(values[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const settings = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded"

      },
      body: formBody
    };
    try {
      const res = await (
        await fetch(
          "https://beheer.emmencentrum.nl/news.php",
          settings
        )
      ).json();

      if (res.errors) {
        setErrors(res.errors);
      }
      if (res) {
        console.log(res);
        setFormSent(true);
      }
    } catch (errors) {
      console.log(errors);
    }
  }

  return {
    handleSubmit,
    onChange,
    values,
    errors,
    formSent,
  };
}
