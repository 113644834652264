import { Route, Switch } from "react-router-dom";
import PageProvider from "../../providers/PageProvider";
import Home from "../../pages/Home";
import Page from "../../pages/Page";
import NewsArchive from "../../pages/NewsArchive";
import PostProvider from "../../providers/PostProvider";
import Post from '../../pages/Post';
import EventCategoryProvider from "../../providers/EventCategoryProvider";
import EventCategory from "../../pages/EventCategory";
import EventProvider from "../../providers/EventProvider";
import Event from "../../pages/Event";
import CompanyCategoryProvider from "../../providers/CompanyCategoryProvider";
import CompanyCategory from "../../pages/CompanyCategory";
import CompanyProvider from "../../providers/CompanyProvider";
import Company from "../../pages/Company";
import NotFound from "../../pages/NotFound";
import TestPage from "../../pages/TestPage";

export default function RouteCollection() {
  return (
    <Switch>
      <Route exact path="/" >
        <PageProvider>
          <Home />
        </PageProvider>
      </Route>
      <Route exact path="/nieuws">
        <PageProvider>
          <NewsArchive />
        </PageProvider>
      </Route>
      <Route exact path="/nieuws/:slug">
        <PostProvider>
          <Post />
        </PostProvider>
      </Route>
      <Route exact path="/agenda">
        <PageProvider>
          <Page />
        </PageProvider>
        <EventCategoryProvider>
          <EventCategory />
        </EventCategoryProvider>
      </Route>
      <Route exact path="/evenementen/:slug">
        <EventProvider>
          <Event />
        </EventProvider>
      </Route>

      <Route exact path="/testpage" component={TestPage}></Route>
      <Route exact path="/bedrijven">
        <PageProvider>
          <Page />
        </PageProvider>
        <CompanyCategoryProvider>
          <CompanyCategory />
        </CompanyCategoryProvider>
      </Route>
      <Route exact path="/bedrijven/:slug">
        <CompanyProvider>
          <Company />
        </CompanyProvider>
      </Route>

      <Route exact path="/:slug">
        <PageProvider>
          <Page />
        </PageProvider>
      </Route>

      <Route component={NotFound} />
    </Switch>
  );
}
