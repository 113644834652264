import { useEffect, useRef, useState } from "react";
import mapboxgl, { Map } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { MAP_BOX_TOKEN } from "../../../constants";
import MapBlockOverlay from "./mapBlockOverlay";

mapboxgl.accessToken = MAP_BOX_TOKEN;

export default function MapBlock(props) {
  const { geojson, resize, cluster } = props;

  //current map, for use in overlay
  const [currentMap, setCurrentMap] = useState(null);

  const map = useRef(null);
  const mapContainer = useRef(null);

  //fit bounds
  function fitBounds(geojson) {
    if (geojson.features.length > 1) {
      var boundCoordinates = geojson.features.map(feature => feature.geometry.coordinates.slice());

      var bounds = boundCoordinates.reduce(function (bounds, coord) {
        return bounds.extend(coord);
      }, new mapboxgl.LngLatBounds(boundCoordinates[0], boundCoordinates[0]));

      map.current.fitBounds(bounds, {
        padding: 100
      });
    }
  }

  //resize
  useEffect(() => {
    resize && map.current?.resize();
  }, [resize]);

  useEffect(() => {
    //map can only be set once, so if you want to update the map, use setData to change the source
    if (map.current?.getSource('companies')) {
      map.current.getSource('companies').setData(geojson);

      fitBounds(geojson);
    }

    if (mapContainer.current && !map.current && geojson.features.length > 0) {
      let centerLatitude = (Math.min(...geojson.features.map(feature => feature.geometry.coordinates[1])) + Math.max(...geojson.features.map(feature => feature.geometry.coordinates[1]))) / 2;
      let centerLongitude = (Math.min(...geojson.features.map(feature => feature.geometry.coordinates[0])) + Math.max(...geojson.features.map(feature => feature.geometry.coordinates[0]))) / 2;

      map.current = new Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/light-v10",
        center: [
          centerLongitude,
          centerLatitude
        ],
        zoom: 17,
        pitch: 45,
        bearing: -17.6,
        maxBounds: [
          [-0.14936937500044678, 50.6025191227169], // Southwest coordinates
          [10.924849374999184, 53.65612937582051] // Northeast coordinates
        ],
      });

      fitBounds(geojson);

      //test marker colors
      const colors = ['blue', 'gray', 'green', 'orange', 'pink', 'purple', 'red', 'yellow'];

      //objects for caching and keeping track of HTML marker objects (for performance)
      let markers = {};
      let markersOnScreen = {};

      map.current.on("load", () => {
        map.current.addSource('companies', {
          'type': 'geojson',
          'data': geojson,
          'cluster': cluster ?? false,
          'clusterMaxZoom': 17,
          'clusterRadius': 50
        });

        //cluster layer
        map.current.addLayer({
          id: 'clusters',
          type: 'circle',
          source: 'companies',
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': [
              'step',
              ['get', 'point_count'],
              '#68a2b9',
              100,
              '#e50695',
              750,
              '#f28cb1'
            ],
            'circle-radius': [
              'step',
              ['get', 'point_count'],
              20,
              100,
              30,
              750,
              40
            ]
          }
        });
        //cluster count
        map.current.addLayer({
          id: 'cluster-count',
          type: 'symbol',
          source: 'companies',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': ['get', 'point_count_abbreviated'],
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 13
          },
          paint: {
            "text-color": "#ffffff"
          }
        });

        // Insert the layer beneath any symbol layer.
        const layers = map.current?.getStyle().layers;
        const labelLayerId = layers.find(
          (layer) => layer.type === 'symbol' && layer.layout['text-field']
        ).id;

        map.current?.addLayer(
          {
            'id': 'add-3d-buildings',
            'source': 'composite',
            'source-layer': 'building',
            'filter': ['==', 'extrude', 'true'],
            'type': 'fill-extrusion',
            'minzoom': 15,
            'paint': {
              'fill-extrusion-color': '#aaa',

              // Use an 'interpolate' expression to
              // add a smooth transition effect to
              // the buildings as the user zooms in.
              'fill-extrusion-height': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                15.05,
                ['get', 'height']
              ],
              'fill-extrusion-base': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                15.05,
                ['get', 'min_height']
              ],
              'fill-extrusion-opacity': 0.6
            }
          },
          labelLayerId
        );
      });

      map.current.on('render', () => {
        if (map.current.getSource('companies')) {
          if (!map.current.isSourceLoaded('companies')) return;
          updateMarkers();
        }
      });

      //update markers
      function updateMarkers() {
        const newMarkers = {};
        const features = map.current.querySourceFeatures('companies');

        for (const feature of features) {
          const coords = feature.geometry.coordinates;
          const props = feature.properties;
          if (props.cluster) continue;
          const id = props.id;

          let marker = markers[id];
          if (!marker) {
            const el = document.createElement('div');
            el.className = 'marker marker-' + colors[Math.floor(Math.random() * colors.length)];

            marker = markers[id] = new mapboxgl.Marker({
              element: el
            }).setLngLat(coords)
              .setPopup(new mapboxgl.Popup({ offset: [0, -40] }).setHTML(props.address ? `<h5>${props.title}</h5><p>${props.address}</p>` : `<h5>${props.title}</h5>`));
          }
          newMarkers[id] = marker;

          if (!markersOnScreen[id]) marker.addTo(map.current);
        }
        //for every marker we've added previously, remove those that are no longer visible
        for (const id in markersOnScreen) {
          if (!newMarkers[id]) markersOnScreen[id].remove();
        }
        markersOnScreen = newMarkers;
      }

      //cluster click zoom
      map.current.on('click', 'clusters', (e) => {
        const features = map.current.queryRenderedFeatures(e.point, {
          layers: ['clusters']
        });
        const clusterId = features[0].properties.cluster_id;
        map.current.getSource('companies').getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;

            map.current.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom
            });
          }
        );
      });

      map.current.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          // When active the map will receive updates to the device's location as it changes.
          trackUserLocation: true,
          // Draw an arrow next to the location dot to indicate which direction the device is heading.
          showUserHeading: true
        })
      );

      //set current map, for use in overlay
      setCurrentMap(map.current)
    }
  }, [geojson, cluster]);

  return (
    <div className="placeholdermap position-relative z-0" ref={mapContainer}>
      <MapBlockOverlay map={currentMap} duration={2000} />
    </div>
  );
}