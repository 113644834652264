import useNewsLetter from "../../hooks/useNewsletter";

export default function NewsletterBlock() {
  const { /*values,*/ onChange, handleSubmit, formSent } = useNewsLetter();
  
  return (
    <div className="container-fluid bg-secondary py-3">
      <div className="row">
        <div className="offset-lg-0 col-lg-12 offset-md-3 col-md-6 offset-sm-2 col-sm-8">
          <form id="newsletterform" className="row gx-xl-3 gx-2 gy-3 d-flex justify-content-center align-items-center" onSubmit={handleSubmit}>
            <div className="col-lg-auto col-12"><h4 className="text-white m-0">Blijf gratis up to date</h4></div>
            <div className="col-lg-auto col-12"><input id="txtNewsletterEmail" name="newsemail" type="email" placeholder="E-mail" onChange={onChange}/></div>
            <div className="col-lg-auto col-12"><input id="txtNewsletterName" name="newsname" type="text" placeholder="Naam" onChange={onChange} /></div>
            <div className="col-lg-auto col-12"><button type="submit" disabled={formSent} className="btn btn-outline-white btn-lg">Inschrijven</button></div>
          </form>
        </div>
      </div>
    </div>
  );
}