import { useContext, useState, useEffect } from "react";
import { PageContext } from "../providers/PageProvider";
import HeaderTiles from "../components/blocks/headertile/HeaderTiles";
import CarouselBlock from "../components/blocks/companyCategory/carouselBlock";
import ContentText from "../components/blocks/content/ContentText";
import ContentTextImage from "../components/blocks/content/ContentTextImage";
import CardsSection from "../components/blocks/cards/CardsSection";
import AgendaSection from "../components/blocks/agenda/AgendaSection";

import MapBlock from "../components/blocks/companyCategory/mapBlock";

import { ShuffleArray } from "../tools";
import { REACT_APP_API_URL } from "../constants";

export default function Home() {
  const { pageData } = useContext(PageContext);

  //get companies
  const [companies, setCompanies] = useState(null);

  useEffect(() => {
    fetch(REACT_APP_API_URL + '/wp-content/uploads/companies.json')
      .then(response => response.json())
      .then((json) => {
        setCompanies(shuffleCompanies(json.data.companies));
      })
      .catch((error) => {
      });
  }, [pageData]);

  //shuffle companies, return 8
  function shuffleCompanies(companies) {
    let shuffledCompanies = JSON.parse(JSON.stringify(companies));

    ShuffleArray(shuffledCompanies.nodes);

    shuffledCompanies.nodes = shuffledCompanies.nodes.slice(0, 8);

    return shuffledCompanies;
  }

  //set geojson
  const features = companies?.nodes.filter(company => {
    return company.companySettings.companyData.latitude && company.companySettings.companyData.longitude;
  }).map(company => {
    return (
      {
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [
            company.companySettings.companyData.longitude,
            company.companySettings.companyData.latitude
          ]
        },
        "properties": {
          "id": company.id,
          "title": company.title,
          "address": company.companySettings.companyData.address
        }
      }
    )
  });

  const geojson = {
    "type": "FeatureCollection",
    "features": features
  };

  return (
    <>
      <HeaderTiles data={pageData.blockHeaderHome.gallery} />
      {companies &&
        <>
          <CarouselBlock companyListData={companies} />
          <div className="container-fluid">
            <div className="container-map my-100">
              <div className="row">
                <div className="col-12">
                  <MapBlock geojson={geojson} />
                </div>
              </div>
            </div>
          </div>
        </>
      }

      {/* loop through blocks */}
      {pageData.blocks.map((item, index) => {
        if (item.blockContentText != null)
          return <ContentText key={index} html={item.blockContentText.editor} />;
        if (item.blockContentTextImage != null)
          return <ContentTextImage key={index} html={item.blockContentTextImage.editor} highlight={item.blockContentTextImage.highlight} displayLeft={item.blockContentTextImage.displayLeft} imageUrl={item.blockContentTextImage.image.sourceUrl} button={item.blockContentTextImage.button} />;
        if (item.blockCards != null)
          return <CardsSection key={index} cards={item.blockCards?.cards} />;
        if (item.blockEvents != null)
          return <AgendaSection key={index} html={item.blockEvents?.text} button={item.blockEvents?.button} />;
        return null;
      })}
    </>
  );
}