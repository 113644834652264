import iconGallery from "../../../../assets/icon-gallery.svg";
import TitleBar from "./TitleBar";
import Fancybox from "../../Fancybox";

//fancybox settings
const fancyboxSettings = {
  Hash: false,
  infinite: true,
  hideScrollbar: false,
  Thumbs: {
    autoStart: false,
  },
  Toolbar: {
      autoEnable: false,
      display: [
          "counter",
          "slideshow",
          "fullscreen",
          "close"
      ]
  },
  on: {
      done: (fancybox, slide) => {
          if (slide.type === "image") {
              //prevent rightclick
              const images = document.querySelectorAll(".fancybox__image");
              for (const image of images) {
                  image.addEventListener("contextmenu", event => event.preventDefault());
              }
          }
      }
  }
};

export default function ShopGallery(props) {
  const { gallery } = props;

  if (!gallery) return null;

  return (
    <>
      <div className="shop-block bg-white rounded-3">
        <TitleBar icon={iconGallery} title="Galerij" />
        <div className="row g-2">
          {gallery.map((item, index) => {
            return <Fancybox key={index} options={fancyboxSettings}>
              <div className="col-md-4 col-6">
                <div className="imagewrapper">
                  <img src={item.mediumImage} alt="" role="button" data-fancybox="gallery" data-src={item.image} data-caption={item.title} />
                </div>
              </div>
            </Fancybox>
          })}
        </div>
      </div>

      {/* <div id="galleryModal" className="modal fade" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body p-0">
              <div id="galleryCarousel" className="carousel slide" data-bs-interval="false">
                <div className="carousel-inner">
                  {gallery.map((item, index) => {
                    return <div key={index} className={`carousel-item${index === 0 ? " active" : ""}`}>
                      <div className="imagewrapper ar ar-shop">
                        <img src={item.image} alt="" className="ar-content" />
                      </div>
                      {item.title &&
                        <div className="carousel-caption d-block bottom-0 start-0 end-0">
                          <p className="text-light-grey fw-bold">{item.title}</p>
                        </div>}
                    </div>
                  })}
                </div>
                {gallery.length > 1 &&
                <>
                <button className="carousel-control-prev" type="button" data-bs-target="#galleryCarousel" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#galleryCarousel" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
                </>}
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}