/**
 * Here you can add function that you use a lot
 * for example to get the Authtoken out of the localstorage.
 */
export const flatListToHierarchical = (
  data = [],
  { idKey = "key", parentKey = "parentId", childrenKey = "children" } = {}
) => {
  const tree = [];
  const childrenOf = {};
  data.forEach((item) => {
    const newItem = { ...item };
    const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;
    childrenOf[id] = childrenOf[id] || [];
    newItem[childrenKey] = childrenOf[id];
    parentId
      ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
      : tree.push(newItem);
  });
  return tree;
};

//get day of week
export const GetDayOfWeek = (timestamp) => {
  const options = { timeZone: 'Europe/Amsterdam', weekday: 'long' }

  return new Intl.DateTimeFormat('nl-NL', options).format(new Date(timestamp * 1000));
};

//get day of week short
export const GetDayOfWeekShort = (timestamp) => {
  const options = { timeZone: 'Europe/Amsterdam', weekday: 'short' }

  return new Intl.DateTimeFormat('nl-NL', options).format(new Date(timestamp * 1000));
};

//get short date
export const GetShortDate = (timestamp) => {
  const options = { timeZone: 'Europe/Amsterdam', dateStyle: 'short' }

  return new Intl.DateTimeFormat('nl-NL', options).format(new Date(timestamp * 1000));
}

//get short date with forward slash
export const GetShortDateUK = (timestamp) => {
  const options = { timeZone: 'Europe/Amsterdam', dateStyle: 'short' }

  return new Intl.DateTimeFormat('en-GB', options).format(new Date(timestamp * 1000));
}

//get date without separators
export const GetDateYmd = (timestamp) => {
  const options = { timeZone: 'Europe/Amsterdam', year: 'numeric', month: '2-digit', day: '2-digit' }

  var myDate = new Intl.DateTimeFormat('nl-NL', options).formatToParts(new Date(timestamp * 1000))

  return myDate[4].value + '' + myDate[2].value + '' + myDate[0].value;
}

//shuffle array
export const ShuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}