import { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HeaderTilesProgressBar from "./HeaderTilesProgressBar";
import CustomLink from "../../CustomLink";

export default function HeaderTiles(props) {
  const { data } = props;

  //slick ref
  const slickRef = useRef();

  if (!data) return null;

  //number of slides 2 or 3
  const slideCount = data.length;

  //breakpoint for slider
  const breakpoint = 992;

  //slick settings
  const slickSettings = {
    accessibility: false,
    arrows: false,
    draggable: false,
    initialSlide: 1,
    slidesToScroll: 1,
    slidesToShow: slideCount,
    swipe: false,
    responsive: [
      {
        breakpoint: breakpoint,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  return (
    <div className="my-header-block2 position-relative">
      <div className="container-fluid px-0">
        <div className="text-center">
          <Slider {...slickSettings} ref={slickRef}>
            {data.map((item, index) => {
              return (
                <div key={index} className="position-relative">
                  <div className="header-body w-100 h-100 px-5 position-absolute top-50 start-50 translate-middle d-flex flex-column justify-content-center align-items-center">
                    <h2 className="display-2 display-2-xl">{item.title}</h2>
                    <h4 className="display-4 display-4-xl fw-normal text-uppercase">{item.subtitle}</h4>
                    <CustomLink href={item.button.url} className="btn btn-primary btn-lg stretched-link mt-3">{item.button.title}</CustomLink>
                  </div>
                  <div className="imagewrapper">
                    <img src={item.image.sourceUrl} alt="" />
                  </div>
                </div>
              )
            })}
          </Slider>
          <HeaderTilesProgressBar slickRef={slickRef} breakpoint={breakpoint} autoplaySpeed={6} />
        </div>
      </div>
    </div>
  );
}