import gql from "graphql-tag";

/**
 * get all categories + subcategories + count
 */
export const GetCompanyCategories = gql`
query GetCompanyCategories($search: String) {
  companyCategories(where: {hideEmpty: true, parent: 0}) {
    nodes {
      id: databaseId
      name
      url: slug
      children(first: 25, where: {hideEmpty: true, hierarchical: false}) {
        nodes {
          id: databaseId
          name
          url: slug
          results: companies(where: {search: $search}) {
            pageInfo {
              offsetPagination {
                total
              }
            }
          }
        }
      }
    }
  }
}
`;