import { createContext } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GetEvent } from "../queries/GetEvent";
import LoadingSpinner from "../components/spinner/LoadingSpinner";
import NotFound from "../pages/NotFound";

//create a pagecontext with the createContext hook
export const EventContext = createContext({});

export default function EventProvider(props) {
  //get slug
  const { slug } = useParams();

  //get event
  const { loading, error, data } = useQuery(GetEvent, {
    variables: { slug: slug }
  });

  if (loading) return <LoadingSpinner />;
  if (error) return <div>Error</div>;

  if (!data.event) return <NotFound />;

  //create a variable that contains the data from graphql query
  const eventData = data.event;

  return (
    <EventContext.Provider value={{ eventData }}>
      {props.children}
    </EventContext.Provider>
  );
}