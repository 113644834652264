import { createContext } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GetCompany } from "../queries/GetCompany";
import LoadingSpinner from "../components/spinner/LoadingSpinner";
import NotFound from "../pages/NotFound";

//create a pagecontext with the createContext hook
export const CompanyContext = createContext({});

export default function CompanyProvider(props) {
  //get slug
  const { slug } = useParams();

  //get company
  const { loading, error, data } = useQuery(GetCompany, {
    variables: { slug: slug }
  });

  if (loading) return <LoadingSpinner />;
  if (error) return <div>Error</div>;

  if (!data.company) return <NotFound />;

  //create a variable that contains the data from graphql query
  const companyData = data.company;

  return (
    <CompanyContext.Provider value={{ companyData }}>
      {props.children}
    </CompanyContext.Provider>
  );
}