import { useEffect } from "react"

export default function useTitle(title) {
  useEffect(() => {
    const prevTitle = document.title

    if (title)
      document.title = document.title + " - " + title

    return () => {
      document.title = prevTitle
    }
  }, [title])
}