import gql from "graphql-tag";

/**
 * get latest events
 */
export const GetLatestEvents = gql`
query GetLatestEvents($quantity: Int!, $dates: [String!]) {
  events(first: $quantity, where: {eventBetweenDates: $dates, orderby: {field: EVENT_START_DATE, order: ASC}}) {
    pageInfo {
      offsetPagination {
        hasMore
        hasPrevious
        total
      }
    }
    nodes {
      id: databaseId
      title
      url: slug
      image: featuredImage {
        node {
          sourceUrl
        }
      }
      eventSettings {
        beginDate
        endDate
        mustSee
      }
      eventCategories(first: 1) {
        nodes {
          name
          slug
        }
      }
    }
  }
}
`;