import gql from "graphql-tag";

/**
 * get news items
 */
export const GetNewsItems = gql`
query GetNewsItems($offset: Int!, $size: Int!) {
  posts(where: {offsetPagination: {offset: $offset, size: $size}, orderby: {field: DATE, order: DESC}}) {
    pageInfo {
      offsetPagination {
        hasMore
        hasPrevious
        total
      }
    }
    nodes {
      id: databaseId
      title
      url: slug
      image: featuredImage {
        node {
          sourceUrl
          altText
          title
        }
      }
      excerpt
    }
  }
}
`;