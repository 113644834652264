/**
 * Loading spinner component, use this for the loading if statement
 */

import React from "react";
import logo from "../../../assets/logo.svg";

export default function LoadingSpinner() {
    return (
        <div className="d-flex h-100 w-100 position-fixed bg-white z-1">
            <div className="container align-self-center text-center">
                <img src={logo} alt="Laden..." height="120px" width="100%" />
            </div>
        </div>
    );
}
