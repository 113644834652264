import ReactHtmlParser from 'react-html-parser';
import CustomLink from './CustomLink';

export default function CustomHtml(props) {
  const { html } = props;

  function transform(node, index) {
    if (node.type === 'tag' && node.name === 'a') {
      return <CustomLink key={index} href={node.attribs.href}>{node.children[0].data}</CustomLink>
    }
  }

  return (
    ReactHtmlParser(html, { transform: transform })
  )
}