export default function HeaderBlockEvent(props) {
  const { imageUrl, title, subtitle, mustsee, startDate, endDate } = props;

  return (
    <div className="my-header-block position-relative">
      <div className="imagewrapper">
        {imageUrl && <img src={imageUrl} alt="" />}
      </div>
      <div className="container position-absolute top-50 start-50 translate-middle">
        <div className="row px-xl-5 px-3 mx-xl-5">
          <div className="col">
            {mustsee && <span className="badge rounded-pill bg-secondary text-uppercase px-3 fs-6 lh-base">Must see</span>}
            {subtitle && <h4 className="subtitle display-4 fw-normal text-white">{subtitle}</h4>}
            <h2 className="display-2 text-white">{title}</h2>
            {!(!startDate || !endDate) ? <span className="text-white">{startDate === endDate ? startDate : startDate + " - " + endDate}</span> : null}
          </div>
        </div>
      </div>
    </div>
  );
}