export default function HeaderBlock(props) {
  const { imageUrl, title, subtitle } = props;

  return (
    <div className="my-header-block position-relative">
      <div className="imagewrapper">
        {imageUrl && <img src={imageUrl} alt="" />}
      </div>
      <div className="container position-absolute top-50 start-50 translate-middle">
        <div className="row px-xl-5 px-3 mx-xl-5">
          <div className="col">
            {subtitle && <h4 className="subtitle display-4 fw-normal text-white">{subtitle}</h4>}
            <h1 className="display-1 text-white">{title}</h1>
          </div>
        </div>
      </div>
    </div>
  );
}