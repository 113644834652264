import iconTitle from "../../../../assets/icon-title.svg";
import TitleBar from "./TitleBar";
import CustomHtml from "../../CustomHtml";

export default function ShopText(props) {
  const { info } = props;

  if (info.hidden) return null;

  return (
    <div className="shop-block bg-white rounded-3">
      <TitleBar icon={iconTitle} title={info.title} />
      <div>
        <CustomHtml html={info.description} />
      </div>
    </div>
  );
}