import Header from "./components/navigation/Header";
import RouteCollection from "./components/routing/RouteCollection";
import Footer from "./components/footer/Footer";
import { Offcanvas } from "bootstrap";

//main component
export default function App() {
  return (
    <>
      <Header offcanvas={Offcanvas}/>
      <RouteCollection />
      <Footer />
    </>
  );
}