/**
 * Export the URL's
 */

//export const REACT_APP_API_URL ="https://cmsec.ls01.dotsolutions.nl";
export const REACT_APP_API_URL ="https://beheer.emmencentrum.nl";
//export const REACT_APP_API_URL ="http://cmsemmencentrum.test";

export const REACT_APP_GRAPH_API_URL = `${REACT_APP_API_URL}/graphql`;
export const REACT_APP_WP_JSON_API_URL = `${REACT_APP_API_URL}/wp-json`;

export const MAP_BOX_TOKEN = "pk.eyJ1IjoiYmVybmFyZHZpc3NjaGVyIiwiYSI6ImNrdWZiM2pvNzBrcnEyb3Frem1waXN6NW8ifQ.WM7zjVyPaYiUvgojcQQGJA";
/**
 * Get the default fetch headers
 */
export const FETCH_HEADERS = () => {
  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-User-Agent": "admin",
    },
  };
};
