import gql from "graphql-tag";

/**
 * get company by slug
 */
export const GetCompany = gql`
query GetCompany($slug: ID!) {
  company(id: $slug, idType: SLUG) {
    id: databaseId
    title
    url: slug
    image: featuredImage {
      node {
        sourceUrl
      }
    }
    companySettings {
      companyData {
        address
        postcode
        city
        email
        phoneNumber
        latitude
        longitude
      }
      info {
        hidden
        title
        description
      }
      socialMedia {
        hidden
        platforms {
          platform
          url
        }
      }
      contactForm {
        hidden
        email
        phoneNumber
      }
      gallery {
        image: sourceUrl
        mediumImage: sourceUrl(size: MEDIUM)
        altText
        title
      }
      businessHours {
        day
        openTime
        closeTime
        closed
      }
    }
    companyCategories(first: 1) {
      nodes {
        name
        slug
        parentCategories: ancestors {
          nodes {
            slug
          }
        }
      }
    }
  }
}
`;