import iconSocials from "../../../../assets/icon-socials.svg";
import iconFacebook from "../../../../assets/icon-facebook.svg";
import iconInstagram from "../../../../assets/icon-instagram.svg";
import iconLinkedin from "../../../../assets/icon-linkedin.svg";
import iconTwitter from "../../../../assets/icon-twitter.svg";
import iconYouTube from "../../../../assets/icon-youtube.svg";
import TitleBar from "./TitleBar";

export default function ShopSocials(props) {
  const { socialMedia } = props;

  if (socialMedia.hidden || !socialMedia.platforms) return null;

  const platformIcons = {
    "facebook": iconFacebook,
    "instagram": iconInstagram,
    "linkedin": iconLinkedin,
    "twitter": iconTwitter,
    "youtube": iconYouTube
  };

  const platformNames = {
    "facebook": "Facebook",
    "instagram": "Instagram",
    "linkedin": "LinkedIn",
    "twitter": "Twitter",
    "youtube": "YouTube"
  };

  //"clone" with json so I can sort it before mapping
  const sortedPlatforms = JSON.parse(JSON.stringify(socialMedia.platforms)).sort((a, b) => a.platform.localeCompare(b.platform));

  return (
    <div className="shop-block bg-white rounded-3">
      <TitleBar icon={iconSocials} title="Volg ons!" />
      <div className="row g-lg-4 g-3 block-socials">
        {sortedPlatforms.map((item) => {
          return <div key={item.platform} className="col-xl-4 col-lg-12 col-sm-4">
            <a href={item.url} target="_blank" rel="noreferrer" className="d-inline-flex">
              <div className="iconwrapper d-flex rounded-circle">
                <img src={platformIcons[item.platform]} alt="icon" className="m-auto" />
              </div>
              {platformNames[item.platform]}
            </a>
          </div>
        })}
      </div>
    </div>
  );
}