import iconPin from "../../../../assets/icon-pin.svg";
import TitleBar from "./TitleBar";
import MapBlock from "../companyCategory/mapBlock";

export default function ShopMap(props) {
  const { id, title, companyData } = props;

  const geojson = {
    "type": "FeatureCollection",
    "features": [{
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          companyData.longitude,
          companyData.latitude
        ]
      },
      "properties": {
        "id": id,
        "title": title,
        "address": companyData.address
      }
    }]
  };

  if (!companyData || !(companyData.latitude && companyData.longitude)) return null;

  return (
    <div className="shop-block bg-white rounded-3">
      <TitleBar icon={iconPin} title="Contact" />
      <div className="block-map">
        <MapBlock geojson={geojson} />
        <div className="row">
          <div className="col-md-8">
            <p>
              {companyData.address}, {companyData.postcode} {companyData.city}<br />
              {companyData.email && <>{companyData.email}<br /></>}
              {companyData.phoneNumber}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}