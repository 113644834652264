import iconClock from "../../../../assets/icon-clock.svg";
/* import iconSelect from "../../../../assets/icon-select.svg"; */
import TitleBar from "./TitleBar";

export default function ShopOpenHours(props) {
  const { businessHours } = props;

  if (!businessHours) return null;

  let days = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag', 'Koopzondag'];
  /* let vandaag = new Date().getDay(); */

  //"clone" with json so I can sort it before mapping
  const sortedBusinessHours = JSON.parse(JSON.stringify(businessHours)).sort((a, b) => (a.day ?? 7) - (b.day ?? 7));

  return (
    <div className="shop-block bg-white rounded-3">
      <TitleBar icon={iconClock} title="Openingstijden" />
      <div>
        {sortedBusinessHours?.map((item) => {
          return !item.closed ? <p key={item.day ?? 0}>{days[item.day ?? 0]}: {item.openTime} - {item.closeTime}</p> : null
        })}
      </div>
    </div>
  );
}