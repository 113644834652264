import { useQuery } from "@apollo/client";
import { GetFooterSocials } from "../../queries/GetFooterSocials";
import LoadingSpinner from "../spinner/LoadingSpinner";

export default function FooterBottom() {
  const { loading, error, data } = useQuery(GetFooterSocials);

  if (loading) return <LoadingSpinner />;
  if (error) return <div>Error</div>;

  return (
    <div className="row g-4">
      <div className="col-lg-5 col-md-5">
        <h5 className="fw-normal mb-0">© {new Date().getFullYear()}, Vlinderstad Emmen Centrum</h5>
      </div>
      <div className="col-lg-2 col-md-3 d-md-flex justify-content-md-center">
        <div className="row g-4">
          {data.acfOptionsFooter.footerSettings.footerSocials?.map(item => {
            return (
              <div key={item.icon?.id} className="col-auto">
                <a href={item.link} target="_blank" rel="noreferrer" className="d-block">
                  <img src={item.icon?.sourceUrl} alt={item.icon?.altText ?? item.icon?.title} />
                </a>
              </div>
            )
          })}
        </div>
      </div>
      <div className="col-lg-5 col-md-4 text-md-end">
        <h5 className="fw-normal mb-0">Website: <a href="https://webba.nl/" target="_blank" rel="noreferrer">Webba</a></h5>
      </div>
    </div>
  );
}