import { useContext } from "react";
import { Link } from "react-router-dom";
import { PageContext } from "../providers/PageProvider";
import { useQuery } from "@apollo/client";
import { useQueryStringParams } from "../queryStringParams";
import { GetNewsItems } from "../queries/GetNewsItems";
import LoadingSpinner from "../components/spinner/LoadingSpinner";
import HeaderBlock from "../components/blocks/headerimage/headerBlock";
import NewsItemBlock from "../components/blocks/companyCategory/newsItemBlock";
import PagingBlock from "../components/blocks/companyCategory/pagingBlock";
import useTitle from "../hooks/useTitle";

export default function NewsArchive() {
  const { pageData } = useContext(PageContext);

  useTitle(pageData.title)

  //get query string params
  const qsParams = useQueryStringParams();

  let myVariables = {
    currentPage: qsParams.get("p") ? parseInt(qsParams.get("p")) : 1,
    offset: 0,
    size: 12
  }
  myVariables.offset = (myVariables.currentPage - 1) * myVariables.size;

  //get news items
  const { loading, error, data } = useQuery(GetNewsItems, {
    variables: myVariables
  });

  if (loading) return <LoadingSpinner />;
  if (error) return <div>Error</div>;

  return (
    <>
      <HeaderBlock imageUrl={pageData.blockHeader.image?.sourceUrl} title={pageData.blockHeader.title} subtitle={pageData.blockHeader.displaySubtitle ? pageData.blockHeader.subtitle : null} />
      <div className="container py-100">
        {
          //check if nodes exist
          data.posts.nodes.length ?
            <>
              <div className="row position-relative my-news-block mb-100 z-0">
                <div className="col-md-6">
                  <div className="imagewrapper ar ar-golden">
                    <img src={data.posts.nodes[0].image?.node?.sourceUrl} alt="" className="ar-content" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card-body">
                    <h3>{data.posts.nodes[0].title}</h3>
                    <div dangerouslySetInnerHTML={{ __html: data.posts.nodes[0].excerpt }} />
                    <Link to={"/nieuws/" + data.posts.nodes[0].url} title={data.posts.nodes[0].title} className="btn btn-primary stretched-link mt-4">Lees verder</Link>
                  </div>
                </div>
              </div>

              <div className="row g-xl-5 g-4">
                {data.posts.nodes.slice(1).map(newsItem => {
                  return (
                    <div key={newsItem.id} className="col-lg-4 col-md-6">
                      <NewsItemBlock imageUrl={newsItem.image?.node?.sourceUrl} title={newsItem.title} url={"/nieuws/" + newsItem.url} excerpt={newsItem.excerpt} />
                    </div>
                  )
                })}
              </div>

              <div className="row mt-100">
                <div className="text-center paging">
                  <PagingBlock url="/nieuws" currentPage={myVariables.currentPage} itemsPerPage={myVariables.size} itemTotal={data.posts.pageInfo.offsetPagination.total} />
                </div>
              </div>
            </>
            :
            <p>Er zijn geen resultaten gevonden.</p>
        }
      </div>
    </>
  );
}
