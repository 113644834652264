import gql from "graphql-tag";

/**
 * get event by slug
 */
export const GetEvent = gql`
query GetEvent($slug: ID!) {
  event(id: $slug, idType: SLUG) {
    id: databaseId
    title
    url: slug
    blockHeader {
      title
      displaySubtitle
      subtitle
      image {
        sourceUrl
      }
    }
    eventSettings {
      beginDate
      endDate
      mustSee
    }
    blocks {
      ... on AcfContentTextImageBlock {
        blockContentTextImage {
          editor
          highlight
          displayLeft
          image {
            sourceUrl
            altText
            title
          }
          button {
            title
            url
          }
        }
      }
      ... on AcfContentTextBlock {
        blockContentText {
          editor
        }
      }
    }
  }
}
`;