import { useRef, useContext, useEffect } from "react";
import { CompanyContext } from "../providers/CompanyProvider";
import HeaderBlock2 from "../components/blocks/headerimage/headerBlock2";
import ShopText from "../components/blocks/shop-single/ShopText";
import ShopMap from "../components/blocks/shop-single/ShopMap";
import ShopSocials from "../components/blocks/shop-single/ShopSocials";
import ShopOpenHours from "../components/blocks/shop-single/ShopOpenHours";
import ShopGallery from "../components/blocks/shop-single/ShopGallery";
/* import ShopContact from "../components/blocks/shop-single/ShopContact"; */
import useTitle from "../hooks/useTitle";

import Masonry from "masonry-layout";

export default function Company() {
  const { companyData } = useContext(CompanyContext);

  useTitle(companyData.title)

  const masonryRef = useRef();

  useEffect(() => {
    var masonry = new Masonry(masonryRef.current, {
      percentPosition: true,
      horizontalOrder: true
    });

    setTimeout(() => {
      masonry.layout()
    }, 50);
  });

  return (
    <>
      <HeaderBlock2 imageUrl={companyData.image?.node?.sourceUrl} title={companyData.title} />
      <div className="container-fluid py-100 bg-light-grey">
        <div className="container px-0">
          <div className="row g-lg-4 g-3" ref={masonryRef}>
            <div className="col-lg-6">
              <ShopText info={companyData.companySettings.info} />
            </div>
            <div className="col-lg-6">
              <ShopOpenHours businessHours={companyData.companySettings.businessHours} />
            </div>
            <div className="col-lg-6">
              <div className="col-12 mb-lg-4 mb-3">
                <ShopMap id={companyData.id} title={companyData.title} companyData={companyData.companySettings.companyData} />
              </div>
              <div className="col-12">
                <ShopSocials socialMedia={companyData.companySettings.socialMedia} />
              </div>
            </div>
            <div className="col-lg-6">
              <ShopGallery gallery={companyData.companySettings.gallery} />
            </div>
            {/* <div className="col-lg-6">
              <ShopContact contactForm={companyData.companySettings.contactForm} shopID={companyData.id} />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}