import gql from "graphql-tag";

/**
 * get all categories + count
 */
export const GetEventCategories = gql`
query GetEventCategories($timeUnit: TimeUnitEnum, $dates: [String!]) {
  eventCategories(first: 25, where: {hideEmpty: true}) {
    nodes {
      id: databaseId
      name
      url: slug
      results: events(where: {eventTimeUnit: $timeUnit, eventBetweenDates: $dates}) {
        pageInfo {
          offsetPagination {
            total
          }
        }
      }
    }
  }
}
`;