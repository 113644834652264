import { createContext } from "react";
import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { GetPage } from "../queries/GetPage";
import { GetHomepage } from "../queries/GetHomepage";
import LoadingSpinner from "../components/spinner/LoadingSpinner";
import NotFound from "../pages/NotFound";

//create a pagecontext with the createContext hook
export const PageContext = createContext({});

export default function PageProvider(props) {
  //get location
  const location = useLocation();

  //get page
  const { loading, error, data } = useQuery(location.pathname === "/" ? GetHomepage : GetPage, {
    variables: { uri: location.pathname }
  });

  if (loading) return <LoadingSpinner />;
  if (error) return <div>Error</div>;

  if (!data.page) return <NotFound />;

  //create a variable that contains the data from graphql query
  const pageData = data.page;

  return (
    <PageContext.Provider value={{ pageData }}>
      {props.children}
    </PageContext.Provider>
  );
}