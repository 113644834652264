import CustomHtml from "../../CustomHtml";

export default function ContentText(props) {
  const { html } = props;

  return (
    <div className="container my-100">
      <div className="row">
        <div className="col-12">
          <CustomHtml html={html} />
        </div>
      </div>
    </div>
  );
}