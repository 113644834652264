import { Link } from "react-router-dom";

/**
 * 404 Page Not Found
 */
export default function NotFound() {
  return (
    <>
      <div className="my-header-block position-relative">
        <div className="imagewrapper">
        </div>
        <div className="container position-absolute top-50 start-50 translate-middle">
          <h1 className="display-1 text-white">404</h1>
          <h4 className="display-4 fw-normal text-white">
            Deze pagina waar je naar opzoek was bestaat niet (meer).<br />
            Ga terug naar de <Link to="/" className="text-white">homepagina</Link>.
          </h4>
        </div>
      </div>
    </>
  );
}