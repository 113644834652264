import { useState, useEffect } from "react";

export default function MapBlockOverlay(props) {
  const { map, duration } = props;

  //has overlay true/false
  const [hasOverlay, setOverlay] = useState(false);

  useEffect(() => {
    if (map) {
      //timer
      let timer;

      //disable scrollzoom
      map.scrollZoom.disable();

      //map mouse wheel
      map.on("wheel", event => {
        if (event.originalEvent.ctrlKey || event.originalEvent.metaKey) {
          event.originalEvent.preventDefault();

          //enable zoom
          if (!map.scrollZoom._enabled) {
            map.scrollZoom.enable();
          }

          //hide overlay
          setOverlay(false);
        } else {
          //disable zoom
          if (map.scrollZoom._enabled) {
            map.scrollZoom.disable();
          }

          //show overlay
          setOverlay(true);

          //hide overlay after timeout
          clearInterval(timer)
          timer = setTimeout(() => {
            setOverlay(false);
          }, duration)
        }
      });

      //on click
      map.on("mousedown", () => {
        //hide overlay
        setOverlay(false)
      })
    }
  }, [map, duration]);

  //overlay text
  function getOverlayText() {
    if (/(Mac|iPad)/i.test(window.navigator.userAgent))
      return "Use ⌘ + scroll to zoom the map";

    return "Use ctrl + scroll to zoom the map";
  }

  return (
    <div id="map-overlay" className={`${hasOverlay ? "" : "hide-overlay "}position-absolute top-0 bottom-0 start-0 end-0 pe-none`}>
      <h4 className="text-white p-5 d-flex justify-content-center align-items-center w-100 h-100">{getOverlayText()}</h4>
    </div>
  );
}