import { createContext } from "react";
import { useQuery } from "@apollo/client";
import { useQueryStringParams } from "../queryStringParams";
import { GetEvents } from "../queries/GetEvents";
import { GetEventCategories } from "../queries/GetEventCategories";
import LoadingSpinner from "../components/spinner/LoadingSpinner";
import {GetDateYmd} from "../tools";

//create a pagecontext with the createContext hook
export const EventCategoryContext = createContext({});

export default function EventCategoryProvider(props) {
  //get query string params
  const qsParams = useQueryStringParams();

  const timeUnits = {
    "vandaag": "DAY",
    "week": "WEEK",
    "maand": "MONTH"
  };

  let myVariables = {
    categorySlugs: qsParams.get("thema")?.split("_"),
    timeUnit: timeUnits[qsParams.get("filter")],
    dates: qsParams.get("datum")?.split("_"),
    currentPage: qsParams.get("p") ? parseInt(qsParams.get("p")) : 1,
    offset: 0,
    size: 24,
    noDateFilter: false
  }


  if(myVariables.dates === undefined) {
    myVariables.noDateFilter = true;
    let date = new Date();
    let pastDiff =  0;//7 * 24 * 60 * 60;

    let firstDay = GetDateYmd((new Date(date.getFullYear(), date.getMonth(), date.getDate()) / 1000) - pastDiff);
    let lastDay = GetDateYmd((new Date(date.getFullYear()+1, date.getMonth(), date.getDate()) / 1000) - pastDiff);

    let dates = [];
    dates.push(firstDay);
    dates.push(lastDay);

    myVariables.dates = dates;
  }
  myVariables.offset = (myVariables.currentPage - 1) * myVariables.size;

  console.log("myVariables", myVariables);

  //get events
  const { loading, error, data } = useQuery(GetEvents, {
    variables: myVariables
  });

  //get categories
  const { loading: loading2, error: error2, data: data2 } = useQuery(GetEventCategories, {
    variables: {
      timeUnit: myVariables.timeUnit,
      dates: myVariables.dates
    }
  });

  if (loading || loading2) return <LoadingSpinner />;
  if (error || error2) return <div>Error</div>;

  //create a variable that contains the data from graphql query
  const eventListData = data.events;

  //create a variable that contains the data from graphql query, "clone" with json so I can extend it with checked true/false
  const categoryListData = JSON.parse(JSON.stringify(data2.eventCategories));

  //extra filter, can't do it with graphql...
  for (var i = categoryListData.nodes.length - 1; i >= 0; i--) {
    let events = categoryListData.nodes

    for (var j = events.length - 1; j >= 0; j--) {
      if (events[j].results.pageInfo.offsetPagination.total === 0) {
        events.splice(j, 1)
      }
    }
  }

  categoryListData.nodes.map((item) => {
    myVariables.categorySlugs?.includes(item.url) ? item["checked"] = true : item["checked"] = false;

    return item;
  })

  return (
    <EventCategoryContext.Provider value={{ eventListData, categoryListData, myVariables }}>
      {props.children}
    </EventCategoryContext.Provider>
  );
}