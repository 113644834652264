import gql from "graphql-tag";

/**
 * get footer social media data
 */
export const GetFooterSocials = gql`
query GetFooterSocials {
  acfOptionsFooter {
    footerSettings {
      footerSocials {
        icon {
          id: databaseId
          sourceUrl
          altText
          title
        }
        link
      }
    }
  }
}
`;