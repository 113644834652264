import { Link } from "react-router-dom";
import { REACT_APP_API_URL } from "../constants";

export default function CustomLink(props) {
  const { className, href , opts} = props;

  let url = href.replace(REACT_APP_API_URL, "")

  return (
    /^https?:\/\//.test(url)
      ? <a href={url} target="_blank" rel="noreferrer" className={className}>{props.children}</a>
        : <Link to={url} className={className} ><span {...opts}>{props.children}</span></Link>
  )
}
