import CustomLink from "../../CustomLink";
import { GetDayOfWeek, GetDayOfWeekShort, GetShortDateUK } from "../../../tools";

export default function EventItemBlock(props) {
  const { imageUrl, title, url, categoryTitle, mustSee, startDate, endDate } = props;

  return (
    <div className="my-event-block d-flex flex-column position-relative z-0">
      <div className="imagewrapper ar ar-3-4">
        {imageUrl && <img src={imageUrl} alt="" className="ar-content" />}
      </div>
      {mustSee &&
        <div className="position-absolute bottom-0 top-0 start-0 end-0 overflow-hidden">
          <div className="ribbon position-absolute top-0 end-0 bg-secondary text-center text-uppercase text-white p-1">Must see</div>
        </div>
      }
      <div className="position-absolute top-0 p-4">
        <h4 className="text-white fw-normal">
          {startDate === endDate
            ?
            <>
              <span className="text-capitalize">{GetDayOfWeek(startDate)}</span>
              <br />
              {GetShortDateUK(startDate)}
            </>
            :
            <>
              <span className="text-capitalize">{GetDayOfWeekShort(startDate) + " - " + GetDayOfWeekShort(endDate)}</span>
              <br />
              {GetShortDateUK(startDate)} t/m<br />{GetShortDateUK(endDate)}
            </>
          }
        </h4>
      </div>
      <div className="position-absolute w-100 h-100 p-4 d-flex flex-column justify-content-end">
        {categoryTitle && <span className="subtitle text-white">{categoryTitle}</span>}
        <h3><CustomLink href={url} className="stretched-link text-white text-decoration-none">{title}</CustomLink></h3>
      </div>
    </div>
  );
}