import { Link } from "react-router-dom";
import iconArrow from "../../../../assets/arrow.svg";
import storeDefault from "../../../../assets/store-default.jpg";

export default function CompanyBlock(props) {
  const { imageUrl, title, url, categoryTitle } = props;

  let imgUrl = storeDefault;
  if(imageUrl)
  {
      imgUrl = imageUrl;
  }

  return (
    <div className="my-custom-block d-flex flex-column position-relative z-0">
      <div className="imagewrapper ar ar-shop">
        {imgUrl && <img src={imgUrl} alt="" className="ar-content" />}
      </div>
      <div className="position-absolute w-100 h-100 p-3 pe-5 d-flex flex-column justify-content-end">
        <div className="title text-white"><Link to={url} className="stretched-link text-white text-decoration-none fw-bold">{title}</Link></div>
        <div className="category-title text-white">{categoryTitle}</div>
      </div>
      <div className="position-absolute bottom-0 end-0 p-3">
        <img src={iconArrow} alt="" className="icon" />
      </div>
    </div>
  );
}