import { Link } from "react-router-dom";
import iconArrow from "../../../../assets/arrow.svg";
import { GetDayOfWeek, GetDayOfWeekShort, GetShortDate } from "../../../tools";

export default function EventBlock(props) {
  const { imageUrl, title, url, mustSee, startDate, endDate } = props;

  return (
    <div className="my-custom-block d-flex flex-column position-relative z-0">
      <div className="imagewrapper ar ar-shop">
        {imageUrl && <img src={imageUrl} alt="" className="ar-content" />}
      </div>
      {mustSee &&
        <div className="position-absolute bottom-0 top-0 start-0 end-0 overflow-hidden">
          <div className="ribbon position-absolute top-0 end-0 bg-secondary text-center text-uppercase text-white p-1">Must see</div>
        </div>
      }
      <div className="position-absolute top-0 p-3">
        <div className="text-white lh-sm">
          {startDate === endDate
            ?
            <>
              <span className="text-capitalize">{GetDayOfWeek(startDate)}</span>
              <br />
              {GetShortDate(startDate)}
            </>
            :
            <>
              <span className="text-capitalize">{GetDayOfWeekShort(startDate) + " - " + GetDayOfWeekShort(endDate)}</span>
              <br />
              {GetShortDate(startDate)} t/m<br />{GetShortDate(endDate)}
            </>
          }
        </div>
      </div>
      <div className="position-absolute w-100 h-100 p-3 pe-5 d-flex flex-column justify-content-end">
        <div className="title text-white pb-1"><Link to={url} className="stretched-link text-white text-decoration-none fw-bold">{title}</Link></div>
      </div>
      <div className="position-absolute bottom-0 end-0 p-3">
        <img src={iconArrow} alt="" className="icon" />
      </div>
    </div>
  );
}