import { useContext } from "react";
import { EventContext } from "../providers/EventProvider";
import ContentText from "../components/blocks/content/ContentText";
import ContentTextImage from "../components/blocks/content/ContentTextImage";
import HeaderBlockEvent from "../components/blocks/headerimage/headerBlockEvent";
import { GetShortDate } from "../tools";
import useTitle from "../hooks/useTitle";

export default function Event() {
  const { eventData } = useContext(EventContext);
  
  useTitle(eventData.title)

  return (
    <>  
        <HeaderBlockEvent 
        imageUrl={eventData.blockHeader.image?.sourceUrl} 
        title={eventData.blockHeader.title} 
        subtitle={eventData.blockHeader.displaySubtitle ? eventData.blockHeader.subtitle : null} 
        mustsee={eventData.eventSettings.mustSee}
        startDate={GetShortDate(eventData.eventSettings.beginDate)}
        endDate={GetShortDate(eventData.eventSettings.endDate)}
        />
      {/* loop through blocks */}
      {eventData.blocks.map((item, index) => {
        if (item.blockContentText != null)
          return <ContentText key={index} html={item.blockContentText.editor} />;
        if (item.blockContentTextImage != null)
          return <ContentTextImage key={index} html={item.blockContentTextImage.editor} highlight={item.blockContentTextImage.highlight} displayLeft={item.blockContentTextImage.displayLeft} imageUrl={item.blockContentTextImage.image.sourceUrl} button={item.blockContentTextImage.button} />;
        return null;
      })}
    </>
  );
}