export default function ResultsBlock(props) {
  const { currentPage, itemsPerPage, itemTotal } = props;

  const startQuantity = ((currentPage - 1) * itemsPerPage) + 1;
  const endQuantity = (startQuantity + itemsPerPage) - 1;

  return (
    <>
      <strong>{startQuantity > itemTotal ? itemTotal : startQuantity} - {endQuantity > itemTotal ? itemTotal : endQuantity}</strong> van <strong>{itemTotal}</strong>
    </>
  );
}