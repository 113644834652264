import iconSearch from "../../../../assets/search-color.svg";

import { useState } from "react";
import {useHistory} from "react-router-dom";


export default function SearchBlock(props) {
  const { placeholder } = props;

  let history = useHistory();

  const [search, setSearch] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();

    history.push({ "search": "search=" + search , state: { search: search }})
  }

  return (
    <form className="position-relative rounded-pill overflow-hidden" onSubmit={handleSubmit}>
        <input id="q" name="q" type="text" className="form-control rounded-pill pe-5" placeholder={placeholder} maxLength="255" value={search} onChange={e => setSearch(e.target.value)} />
        <button type="submit" className="btn btn-link position-absolute top-50 end-0 translate-middle-y px-3 py-2"><img src={iconSearch} alt="zoeken" /></button>
    </form>
  );
}
