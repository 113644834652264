import { createContext } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GetPost } from "../queries/GetPost";
import LoadingSpinner from "../components/spinner/LoadingSpinner";
import NotFound from "../pages/NotFound";

//create a pagecontext with the createContext hook
export const PostContext = createContext({});

export default function PostProvider(props) {
  //get slug
  const { slug } = useParams();

  //get post
  const { loading, error, data } = useQuery(GetPost, {
    variables: { slug: slug }
  });

  if (loading) return <LoadingSpinner />;
  if (error) return <div>Error</div>;

  if (!data.post) return <NotFound />;

  //create a variable that contains the data from graphql query
  const postData = data.post;

  return (
    <PostContext.Provider value={{ postData }}>
      {props.children}
    </PostContext.Provider>
  );
}