import gql from "graphql-tag";

/**
 * get menu by location
 */
export const GetMenu = gql`
query GetMenu($location: MenuLocationEnum) {
  menuItems(first: 100, where: {location: $location}) {
    nodes {
      key: id
      parentId
      title: label
      path
    }
  }
}
`;