import { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CompanyBlock from "./companyBlock";
import iconArrow from "../../../../assets/arrow-button.svg";
import CustomLink from "../../CustomLink";

export default function CarouselBlock(props) {
  const { companyListData } = props;

  //slick ref
  const slickRef = useRef();

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={{ ...style }} onClick={onClick}>
        <img src={iconArrow} alt="" data-id="slickItems1" className="next" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={{ ...style }} onClick={onClick}>
        <img src={iconArrow} alt="" data-id="slickItems1" className="prev" />
      </div>
    );
  }

  //slick settings
  const slickSettings = {
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToScroll: 1,
    slidesToShow: 4,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 3
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '160px',
          slidesToScroll: 1,
          slidesToShow: 1
        }
      },
      {
        breakpoint: 640,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '120px',
          slidesToScroll: 1,
          slidesToShow: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '80px',
          slidesToScroll: 1,
          slidesToShow: 1
        }
      }
    ]
  }

  return (
    <div className="container-xxxl px-0 my-100">
      <h1 className="text-center pb-5">Onze winkels en horeca</h1>
      <div className="slickItems1">
        <Slider {...slickSettings} ref={slickRef}>
          {companyListData.nodes.map((company, index) => {
            return (
              <div key={index}>
                <CompanyBlock imageUrl={company.mediumImage?.node?.sourceUrl} title={company.title} url={"/bedrijven/" + company.url} categoryTitle={company.companyCategories.nodes[0]?.name} />
              </div>
            )
          })}
        </Slider>
      </div>
      <div className="text-center pt-5">
        <CustomLink href="/bedrijven" className="btn btn-primary btn-lg my-4">Ontdek alles</CustomLink>
      </div>
    </div>
  );
}