import { Link } from "react-router-dom";

export default function NewsItemBlock(props) {
  const { imageUrl, title, url, excerpt } = props;

  return (
    <div className="my-news-block card h-100 position-relative z-0">
        <div className="imagewrapper ar ar-golden">
         {imageUrl && <img src={imageUrl} alt="" className="ar-content" />}
        </div>
        <div className="card-body">
          <h4>{title}</h4>
          <p dangerouslySetInnerHTML={{__html: excerpt}} />
          <Link to={url} title={title} className="btn btn-primary stretched-link mt-4">Lees meer</Link>
        </div>
    </div>
  );
}