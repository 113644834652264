import storeDefault from "../../../../assets/store-default.jpg";

export default function HeaderBlock2(props) {
    const {imageUrl, title, subtitle} = props;

    let imgUrl = storeDefault;
    if (imageUrl) {
        imgUrl = imageUrl;
    }

    return (
        <div className="my-header-block position-relative">
            <div className="imagewrapper">
                {imgUrl && <img src={imgUrl} alt=""/>}
            </div>
            <div className="container position-absolute top-50 start-50 translate-middle">
                <div className="row px-xl-5 px-3 mx-xl-5">
                    <div className="col">
                        {subtitle && <h4 className="subtitle display-4 fw-normal text-white">{subtitle}</h4>}
                        <h2 className="display-2 text-white">{title}</h2>
                    </div>
                </div>
            </div>
        </div>
    );
}