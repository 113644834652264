import { Link } from "react-router-dom";
import { useQueryStringParams } from "../../../queryStringParams";
import iconArrow from "../../../../assets/arrow-button-color.svg";

export default function PagingBlock(props) {
  const { url, currentPage, itemsPerPage, itemTotal } = props;

  const qsParams = useQueryStringParams();

  if (itemTotal === 0) return null;

  const pageTotal = Math.ceil(itemTotal / itemsPerPage);
  const showMaxPages = 4;
  const middleFactor = 1;

  class pagingElement {
    constructor({ className = null, text = null, redirectTo = null }) {
      this.className = className;
      this.text = text;
      this.redirectTo = redirectTo;
    }
  }

  function createPagingElements() {
    let startPage = 1;
    let endPage = pageTotal;

    let elements = [];

    if (pageTotal <= showMaxPages) {
      //previous
      currentPage > 1 && elements.push(new pagingElement({ className: "icon left", text: <img src={iconArrow} alt="" />, redirectTo: (currentPage - 1) }))

      //loop
      for (let i = startPage; i <= endPage; i++) {
        i === currentPage ? elements.push(new pagingElement({ className: "active", text: i })) : elements.push(new pagingElement({ text: i, redirectTo: i }));
      }

      //next
      currentPage !== pageTotal && elements.push(new pagingElement({ className: "icon", text: <img src={iconArrow} alt="" />, redirectTo: (currentPage + 1) }))
    }
    else {
      startPage = currentPage <= (middleFactor + 1) ? 1 : (currentPage + middleFactor) >= pageTotal ? pageTotal - (showMaxPages - 1) : currentPage - middleFactor;
      endPage = currentPage <= (middleFactor + 1) ? showMaxPages : (currentPage + middleFactor) >= pageTotal ? pageTotal : currentPage + middleFactor;
      endPage = endPage > pageTotal ? pageTotal : endPage;

      //previous
      currentPage > 1 && elements.push(new pagingElement({ className: "icon left", text: <img src={iconArrow} alt="" />, redirectTo: (currentPage - 1) }))

      //first
      currentPage > 2 && elements.push(new pagingElement({ text: 1, redirectTo: 1 }));

      //dots
      startPage > 2 && elements.push(new pagingElement({ className: "dots", text: "..." }));

      //loop
      for (let i = startPage; i <= endPage; i++) {
        i === currentPage ? elements.push(new pagingElement({ className: "active", text: i })) : elements.push(new pagingElement({ text: i, redirectTo: i }));
      }

      //dots
      endPage < (pageTotal - 1) && elements.push(new pagingElement({ className: "dots", text: "..." }));

      //last
      currentPage <= (pageTotal - 2) && elements.push(new pagingElement({ text: pageTotal, redirectTo: pageTotal }));

      //next
      currentPage !== pageTotal && elements.push(new pagingElement({ className: "icon", text: <img src={iconArrow} alt="" />, redirectTo: (currentPage + 1) }))
    }

    return elements;
  }

  return (
    <ul className="list-unstyled list-inline mb-0">
      {createPagingElements().map((item, index) => {
        if (item.redirectTo) {
          qsParams.set("p", item.redirectTo);
        }
        return (
          <li key={index} className="list-inline-item overflow-hidden">
            {
              item.redirectTo ?
                <Link className={`d-flex rounded-circle${item.className ? " " + item.className : ""}`} to={{ pathname: url, search: qsParams.toString() }}><span className="m-auto">{item.text}</span></Link> :
                <span className={`d-flex rounded-circle${item.className ? " " + item.className : ""}`}><span className="m-auto">{item.text}</span></span>
            }
          </li>
        )
      })}
    </ul>
  );
}