import HeaderBlock from "../components/blocks/headerimage/headerBlock";
import AgendaSection from "../components/blocks/agenda/AgendaSection";

export default function TestPage() {

  return (
    <>
      <HeaderBlock imageUrl="/images/popcorn.jpg" title="Testpagina" />

      <AgendaSection blockData="" />

      {/* <div className="container-fluid bg-gradient-grey-white py-5">
        <div className="container">
          <h2>Leef je uit in<strong>Emmen</strong></h2>
          <p>Hier komt een super pakkende tekst over de evenementen en activiteiten die in Emmen te beleven zijn. Beleef emmen op zijn puurst met deze game evenementen. Hieronder worden super toffe evenementen geplaatst.</p>
          <div className="row g-xl-5 g-4">

            <div key={0} className="col-lg-4 col-sm-6">
              <div className="my-event-block d-flex flex-column position-relative">
                <div className="imagewrapper ar ar-3-4">
                  <img src="/images/adventure.jpg" alt="" className="ar-content" />
                </div>

                <div className="position-absolute bottom-0 top-0 start-0 end-0 overflow-hidden">
                  <div className="ribbon position-absolute top-0 end-0 bg-secondary text-center text-uppercase text-white p-1">Must see</div>
                </div>

                <div className="position-absolute top-0 p-4">
                  <h4 className="text-white fw-normal text-uppercase">
                    {GetDayOfWeek(1632614400)}
                    <br />
                    {GetShortDateUK(1632614400)}
                  </h4>
                </div>
                <div className="position-absolute w-100 h-100 p-4 d-flex flex-column justify-content-end">
                  <span className="subtitle text-white">Winkelen</span>
                  <h3><CustomLink href="/" className="stretched-link text-white text-decoration-none">Koopzondag Emmen Centrum</CustomLink></h3>
                </div>
              </div>
            </div>

            <div key={1} className="col-lg-4 col-sm-6">
              <div className="my-event-block d-flex flex-column position-relative">
                <div className="imagewrapper ar ar-3-4">
                  <img src="/images/zebra.jpg" alt="" className="ar-content" />
                </div>

                <div className="position-absolute top-0 p-4">
                  <h4 className="text-white fw-normal text-uppercase">
                    {GetDayOfWeek(1632614400)}
                    <br />
                    {GetShortDateUK(1632614400)}
                  </h4>
                </div>
                <div className="position-absolute w-100 h-100 p-4 d-flex flex-column justify-content-end">
                  <span className="subtitle text-white">Winkelen</span>
                  <h3 className="text-white">Koopzondag Emmen Centrum</h3>
                </div>
              </div>
            </div>

            <div key={2} className="col-lg-4 col-sm-6">
              <div className="my-event-block d-flex flex-column position-relative">
                <div className="imagewrapper ar ar-3-4">
                  <img src="/images/auto.jpg" alt="" className="ar-content" />
                </div>
              </div>
            </div>

            <div key={3} className="col-lg-4 col-sm-6">
              <div className="my-event-block d-flex flex-column position-relative">
                <div className="imagewrapper ar ar-3-4">
                  <img src="/images/zebra.jpg" alt="" className="ar-content" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div> */}
    </>
  );
}