import { useContext, useState, useEffect, useMemo, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import { CompanyCategoryContext } from "../providers/CompanyCategoryProvider";
import { useQueryStringParams } from "../queryStringParams";
import SearchBlock from "../components/blocks/companyCategory/searchBlock";
import ResultsBlock from "../components/blocks/companyCategory/resultsBlock";
import CheckboxFilterBlock from "../components/blocks/companyCategory/checkboxFilterBlock";
import RadioFilterBlock from "../components/blocks/companyCategory/radioFilterBlock";
import CompanyBlock from "../components/blocks/companyCategory/companyBlock";
import PagingBlock from "../components/blocks/companyCategory/pagingBlock";
import MapBlock from "../components/blocks/companyCategory/mapBlock";

//import { Offcanvas } from "bootstrap";

export default function CompanyCategory() {
  const { companyListData, categoryListData, myVariables, geojson } = useContext(CompanyCategoryContext);

  const qsParams = useQueryStringParams();

  let history = useHistory();

  //ref for scrolling to results block
  const resultsRef = useRef();
  //scroll only if has params
  useEffect(() => {
    if (qsParams.size) {
      resultsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [qsParams]);

  //display state
  const [displayList, setDisplayList] = useState(getDisplayState);

  function getDisplayState() {
    return JSON.parse(localStorage.getItem('display')) ?? true;
  }

  useEffect(() => {
    localStorage.setItem('display', JSON.stringify(displayList));
  }, [displayList]);

  //filters state
  const defaultFilters = useMemo(() => {
    return [
      { id: 1, name: "Vandaag geopend", url: "vandaag", checked: myVariables.isToday ? true : false },
      { id: 2, name: "Koopavond", url: "koopavond", checked: myVariables.isKoopavond ? true : false }
    ]
  }, [myVariables]);

  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    if (history.location.state?.filters) {
      if (filters !== history.location.state.filters) {
        setFilters(history.location.state.filters);
      }
    }
    else {
      history.replace({ "search": qsParams.toString(), state: { ...history.location.state, filters: defaultFilters } });
      setFilters(defaultFilters);
    }
  }, [defaultFilters, filters, history, qsParams]);

  //types state
  const defaultTypes = categoryListData.nodes;

  const [types, setSelectedTypes] = useState(defaultTypes);

  useEffect(() => {
    if (history.location.state?.types) {
      if (types !== history.location.state.types) {
        setSelectedTypes(history.location.state.types);

        setSelectedCategories(history.location.state.types.find(item => item.checked === true)?.children.nodes);
      }
    }
    else {
      history.replace({ "search": qsParams.toString(), state: { ...history.location.state, types: defaultTypes } });
      setSelectedTypes(defaultTypes);
    }
  }, [defaultTypes, history, qsParams, types]);

  //categories state
  const [categories, setSelectedCategories] = useState(null);

  useEffect(() => {
    if (!qsParams.size) {
      setSelectedCategories(null);
    }
  }, [qsParams]);

  //handle input change
  function handleInputChangeFilters(event, index) {
    var newFilters = filters.map((item, mapIndex) => {
      return mapIndex === index ? { ...item, checked: event.target.checked } : item;
    });

    setFilters(newFilters);

    //remove qs params
    qsParams.delete("p");
    qsParams.delete("filter");

    //set filters
    let filterSlugs = newFilters.filter(item => item.checked).map(item => item.url).join("_");
    filterSlugs.length && qsParams.set("filter", filterSlugs);

    history.push({ "search": qsParams.toString(), state: { filters: newFilters } });
  }

  //handle input change
  function handleInputChangeTypes(event, index) {
    var newTypes = types.map((item, mapIndex) => {
      item.checked = false;

      return mapIndex === index && event.target.checked ? { ...item, checked: event.target.checked } : item;
    });

    setSelectedTypes(newTypes);

    //reset categories
    var newCategories = newTypes.find(item => item.checked)?.children.nodes.map(item => {
      return { ...item, checked: false }
    });
    setSelectedCategories(newCategories);

    //remove qs params
    qsParams.delete("p");
    qsParams.delete("cat");

    //set type
    qsParams.set("type", newTypes[index].url);

    history.push({ "search": qsParams.toString(), state: { types: newTypes, categories: newCategories } });
  }

  //handle input change
  function handleInputChangeCategories(event, index) {
    var newCategories = categories.map((item, mapIndex) => {
      return mapIndex === index ? { ...item, checked: event.target.checked } : item;
    });

    setSelectedCategories(newCategories);

    //remove qs params
    qsParams.delete("p");
    qsParams.delete("cat");

    //set categories
    let categorySlugs = newCategories.filter(item => item.checked).map(item => item.url).join("_");
    categorySlugs.length && qsParams.set("cat", categorySlugs);

    history.push({ "search": qsParams.toString(), state: { categories: newCategories } });
  }

  return (
    <div className="container">
      <div id="offcanvasLeft" className="offcanvas offcanvas-start" data-bs-scroll="true" tabIndex="-1">
        <div className="offcanvas-header">
          <h2 className="offcanvas-title">Filter</h2>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
        </div>
        <div className="offcanvas-body filter-sidebar">
          <CheckboxFilterBlock data={filters} title="Filter" handleInputChange={handleInputChangeFilters} />
          {types.length > 0 && <hr />}
          <RadioFilterBlock data={types} title="Type" groupName="type-offcanvas" handleInputChange={handleInputChangeTypes} />
          {categories?.length > 0 && <hr />}
          <CheckboxFilterBlock data={categories} title="Categorie" handleInputChange={handleInputChangeCategories} />

          <div class="row mt-5 align-items-center">
            <div class="col-6">
              <button type="button" className="btn btn-primary" data-bs-dismiss="offcanvas">Pas toe</button>
            </div>
            <div class="col-6">
              {qsParams.size ? <Link to={"/bedrijven/"}>Verwijder filters</Link> : ''}
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-xl-3">
          <div className="row gy-xl-0 gy-3">
            <div className="col-xl-12 col-lg-4 col-md-5 col-sm-6">
              <SearchBlock placeholder="Zoek je favoriete ..." />
            </div>
            <div className="col-lg-8 col-md-7 col-sm-6 text-end">
              <button type="button" className="btn btn-primary w-auto mb-3 d-xl-none" data-bs-toggle="offcanvas" data-bs-target="#offcanvasLeft">Filter</button>
            </div>
          </div>
        </div>
        <div className="col-xl-9 scroll-mt-100" ref={resultsRef}>
          <div className="row gy-xl-0 gy-3">
            <div className="col-sm-6">
              <div className="btn-group" role="group">
                <input id="btnList" type="radio" name="display" className="btn-check" checked={displayList} onChange={() => setDisplayList(true)} />
                <label className="btn btn-outline-primary" htmlFor="btnList">Lijst</label>
                <input id="btnMap" type="radio" name="display" className="btn-check" checked={!displayList} onChange={() => setDisplayList(false)} />
                <label className="btn btn-outline-primary" htmlFor="btnMap">Kaart</label>
              </div>
            </div>
            <div className="col-sm-6 text-end results">
              <ResultsBlock currentPage={myVariables.currentPage} itemsPerPage={myVariables.size} itemTotal={companyListData.pageInfo.offsetPagination.total} />
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-xl-3 d-none d-xl-block incanvas">
          <div className="filter-sidebar">
            <CheckboxFilterBlock data={filters} title="Filter" handleInputChange={handleInputChangeFilters} />
            {types.length > 0 && <hr />}
            <RadioFilterBlock data={types} title="Type" groupName="type" handleInputChange={handleInputChangeTypes} />
            {categories?.length > 0 && <hr />}
            <CheckboxFilterBlock data={categories} title="Categorie" handleInputChange={handleInputChangeCategories} />
            {qsParams.size ? <div className="mt-4"><Link to={"/bedrijven/"}>Verwijder filters</Link></div> : ''}
          </div>
        </div>
        <div className="col-xl-9">
          <div className={"row g-lg-4 g-3 toggle-display" + (!displayList ? " d-none" : "")}>
            {
              companyListData.nodes.length ?
                companyListData.nodes.map(company => {
                  return (
                    <div key={company.id} className="col-lg-4 col-md-6">
                      <CompanyBlock imageUrl={company.mediumImage?.node?.sourceUrl} title={company.title} url={"/bedrijven/" + company.url} categoryTitle={company.companyCategories.nodes.filter((cat) => { return myVariables.categorySlugs?.includes(cat.slug) ?? cat })[0]?.name} />
                    </div>
                  )
                })
                :
                <p>Er zijn geen resultaten gevonden.</p>
            }
          </div>
          <div className={"row g-lg-4 g-3 toggle-display" + (displayList ? " d-none" : "")}>
            <div className="container-map px-3">
              <MapBlock geojson={geojson} resize={!displayList} cluster={true} />
            </div>
          </div>
        </div>
      </div>
      {displayList && <div className="row mb-5">
        <div className="offset-xl-3 col-xl-9 text-center paging">
          <PagingBlock url="/bedrijven" currentPage={myVariables.currentPage} itemsPerPage={myVariables.size} itemTotal={companyListData.pageInfo.offsetPagination.total} />
        </div>
      </div>}
    </div>
  );
}