import { useContext } from "react";
import { PageContext } from "../providers/PageProvider";
import HeaderBlock from "../components/blocks/headerimage/headerBlock";
import ContentText from "../components/blocks/content/ContentText";
import ContentTextImage from "../components/blocks/content/ContentTextImage";
import CardsSection from "../components/blocks/cards/CardsSection";
import AgendaSection from "../components/blocks/agenda/AgendaSection";
import useTitle from "../hooks/useTitle";

export default function Page() {
  const { pageData } = useContext(PageContext);

  useTitle(pageData.title)

  return (
    <>
      <HeaderBlock imageUrl={pageData.blockHeader.image?.sourceUrl} title={pageData.blockHeader.title} subtitle={pageData.blockHeader.displaySubtitle ? pageData.blockHeader.subtitle : null} />
      {/* loop through blocks */}
      {pageData.blocks.map((item, index) => {
        if (item.blockContentText != null)
          return <ContentText key={index} html={item.blockContentText.editor} />;
        if (item.blockContentTextImage != null)
          return <ContentTextImage key={index} html={item.blockContentTextImage.editor} highlight={item.blockContentTextImage.highlight} displayLeft={item.blockContentTextImage.displayLeft} imageUrl={item.blockContentTextImage.image.sourceUrl} button={item.blockContentTextImage.button} />;
        if (item.blockCards != null)
          return <CardsSection key={index} cards={item.blockCards?.cards} />;
        if (item.blockEvents != null)
          return <AgendaSection key={index} html={item.blockEvents?.text} button={item.blockEvents?.button} />;
        return null;
      })}
    </>
  );
}