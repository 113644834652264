import {useContext, useState, useEffect, forwardRef, useMemo} from "react";
import { EventCategoryContext } from "../providers/EventCategoryProvider";
import { useQueryStringParams } from "../queryStringParams";
import ResultsBlock from "../components/blocks/companyCategory/resultsBlock";
import CheckboxFilterBlock from "../components/blocks/companyCategory/checkboxFilterBlock";
import RadioFilterBlock from "../components/blocks/companyCategory/radioFilterBlock";
import EventBlock from "../components/blocks/companyCategory/eventBlock";
import PagingBlock from "../components/blocks/companyCategory/pagingBlock";

//import { Offcanvas } from "bootstrap";
import iconDate from "../../assets/icon-contact.svg";
import { GetDateYmd } from "../tools";

import "react-datepicker/dist/react-datepicker.min.css";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { addMonths, startOfMonth, endOfMonth } from "date-fns";
import nl from "date-fns/locale/nl";
import {useHistory} from "react-router-dom";

registerLocale("nl", nl);
setDefaultLocale("nl");

export default function EventCategory() {
  const { eventListData, categoryListData, myVariables } = useContext(EventCategoryContext);

  if(myVariables.noDateFilter === true) {
    myVariables.dates = null;
  }

  const qsParams = useQueryStringParams();

  let history = useHistory();

  //datepicker state
  const defaultDate = myVariables.dates != null
    ? addMonths(new Date(myVariables.dates[0].substring(0, 4), myVariables.dates[0].substring(4, 6) - 1, myVariables.dates[0].substring(6, 8)), 0)
    : null;

  const [startDate, setStartDate] = useState(defaultDate);

  useEffect(() => {
    if (history.location.state?.date) {
      if (defaultDate !== history.location.state.date) {
        setStartDate(history.location.state.date)
      }
    }
    else {
      history.replace({ "search": qsParams.toString(), state: { ...history.location.state, date: defaultDate } })
      setStartDate(defaultDate)
    }
    // eslint-disable-next-line
  }, [defaultDate])

  //datepicker element
  const DatePickerElement = forwardRef(({ value, onClick }, ref) => (
    /* { <button className="btn btn-primary" onClick={onClick} ref={ref}>{value}</button> } */
    <div className="form-check d-inline-block" onClick={onClick} ref={ref}>
      <input type="radio" name="wanneer" className="form-check-input" defaultChecked={value ? true : false} />
      <label className="form-check-label">
        {value
          ? value
          : <>Kies datum:</>
        }
        <img src={iconDate} alt="" className="ms-2" />
      </label>
    </div>
  ));

  //filters state
  const defaultFilters = useMemo(() => { return [
    { id: 1, name: "Deze week", url: "week", checked: myVariables.timeUnit === "WEEK" ? true : false },
    { id: 2, name: "Deze maand", url: "maand", checked: myVariables.timeUnit === "MONTH" ? true : false }
  ]}, [myVariables])

  const [filters, setFilters] = useState(defaultFilters)

  useEffect(() => {
    if (history.location.state?.filters) {
      if (filters !== history.location.state.filters) {
        setFilters(history.location.state.filters)
      }
    }
    else {
      history.replace({ "search": qsParams.toString(), state: { ...history.location.state, filters: defaultFilters } })
      setFilters(defaultFilters)
    }
  }, [setFilters, defaultFilters, filters, history, qsParams]);

  //categories state
  const defaultCategories = categoryListData.nodes

  const [categories, setSelectedCategories] = useState(defaultCategories)

  useEffect(() => {
    if (history.location.state?.categories) {
      if (categories !== history.location.state.categories) {
        setSelectedCategories(history.location.state.categories)
      }
    }
    else {
      history.replace({ "search": qsParams.toString(), state: { ...history.location.state, categories: defaultCategories } })
      setSelectedCategories(defaultCategories)
    }
  }, [setSelectedCategories, categories, defaultCategories, history, qsParams])

  //handle input change
  function handleInputChangeFilters(event, index) {
    var newFilters = filters.map((item, mapIndex) => {
      item.checked = false;

      return mapIndex === index && event.target.checked ? { ...item, checked: event.target.checked } : item;
    })

    setFilters(newFilters)

    //remove qs params
    qsParams.delete("p")
    qsParams.delete("filter")
    qsParams.delete("datum")

    //set filter
    qsParams.set("filter", newFilters[index].url)

    history.push({ "search": qsParams.toString(), state: { filters: newFilters } })
  }

  //handle input change
  function handleInputChangeCategories(event, index) {
    var newCategories = categories.map((item, mapIndex) => {
      return mapIndex === index ? { ...item, checked: event.target.checked } : item;
    })

    setSelectedCategories(newCategories)

    //remove qs params
    qsParams.delete("p")
    qsParams.delete("thema")

    //set categories
    let categorySlugs = newCategories.filter(item => item.checked).map(item => item.url).join("_")
    categorySlugs.length && qsParams.set("thema", categorySlugs)

    history.push({ "search": qsParams.toString(), state: { categories: newCategories } })
  }

  //handle datepicker
  function handleDatePicker(date) {
    setStartDate(date);

    var firstDay = GetDateYmd(new Date(date.getFullYear(), date.getMonth(), 1) / 1000);
    var lastDay = GetDateYmd(new Date(date.getFullYear(), date.getMonth() + 1, 0) / 1000);

    //remove qs params
    qsParams.delete("p")
    qsParams.delete("filter")
    qsParams.delete("datum")
    qsParams.delete("thema")

    //set filter
    qsParams.set("datum", firstDay + "_" + lastDay)

    history.push({ "search": qsParams.toString(), state: { date: date } })
  }

  return (
    <div className="container">
      <div id="offcanvasLeft" className="offcanvas offcanvas-start" data-bs-scroll="true" tabIndex="-1">
        <div className="offcanvas-header">
          <h2 className="offcanvas-title">Filter</h2>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
        </div>
        <div className="offcanvas-body">
          <RadioFilterBlock data={filters} title="Wanneer" groupName="wanneer-offcanvas" handleInputChange={handleInputChangeFilters} />
          <DatePicker
            selected={startDate}
            onChange={handleDatePicker}
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            minDate={startOfMonth(addMonths(new Date(), 1))}
            maxDate={endOfMonth(addMonths(new Date(), 25))}
            customInput={<DatePickerElement />}
          />
          {categories?.length > 0 && <hr />}
          <CheckboxFilterBlock data={categories} title="Thema" handleInputChange={handleInputChangeCategories} />

          <br />
          <button type="button" className="btn btn-primary">Pas toe</button>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-xl-3">
          {/* <SearchBlock placeholder="Zoek je favoriete ..." /> */}
        </div>
        <div className="col-xl-9">
          <button type="button" className="btn btn-primary w-auto mb-3 d-xl-none" data-bs-toggle="offcanvas" data-bs-target="#offcanvasLeft">Filter</button>

          <div className="text-end results">
            <ResultsBlock currentPage={myVariables.currentPage} itemsPerPage={myVariables.size} itemTotal={eventListData.pageInfo.offsetPagination.total} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-3 d-none d-xl-block incanvas">
          <div id="filter-sidebar">
            <RadioFilterBlock data={filters} title="Wanneer" groupName="wanneer" handleInputChange={handleInputChangeFilters} />
            <DatePicker
              selected={startDate}
              onChange={handleDatePicker}
              dateFormat="MMMM yyyy"
              showMonthYearPicker
              minDate={startOfMonth(addMonths(new Date(), 1))}
              maxDate={endOfMonth(addMonths(new Date(), 25))}
              customInput={<DatePickerElement />}
            />
            {categories?.length > 0 && <hr />}
            <CheckboxFilterBlock data={categories} title="Thema" handleInputChange={handleInputChangeCategories} />
          </div>
        </div>
        <div className="col-xl-9">
          <div className="row g-lg-4 g-3">
            {
              eventListData.nodes.length ?
                eventListData.nodes.map(event => {
                  return (
                    <div key={event.id} className="col-lg-4 col-md-6">
                      <EventBlock
                        imageUrl={event.mediumImage?.node?.sourceUrl}
                        title={event.title}
                        url={"/evenementen/" + event.url}
                        startDate={event?.eventSettings?.beginDate}
                        endDate={event?.eventSettings?.endDate}
                        mustSee={event?.eventSettings?.mustSee}
                      />
                    </div>
                  )
                })
                :
                <p>Er zijn geen resultaten gevonden.</p>
            }
          </div>
        </div>
      </div>
      <div className="row my-5">
        <div className="offset-xl-3 col-xl-9 text-center paging">
          <PagingBlock url="/agenda" currentPage={myVariables.currentPage} itemsPerPage={myVariables.size} itemTotal={eventListData.pageInfo.offsetPagination.total} />
        </div>
      </div>
    </div>
  );
}
