import CustomHtml from "../../CustomHtml";
import CustomLink from "../../CustomLink";

export default function ContentTextImage(props) {
  const { html, highlight, displayLeft, imageUrl, button } = props;

  return (
    <div className={`container-fluid py-100${highlight ? " bg-light-grey" : ""}`}>
      <div className="container">
        <div className="row gy-5">
          <div className={`col-lg-6 align-self-center${displayLeft ? " order-1 order-lg-2" : ""}`}>
            <div>
              <CustomHtml html={html} />
            </div>
            {button && <CustomLink href={button.url} className="btn btn-primary btn-lg my-4">{button.title}</CustomLink>}
          </div>
          <div className={`col-lg-6 text-left text-lg-center align-self-center${displayLeft ? " order-2 order-lg-1" : ""}`}>
            <img src={imageUrl} alt="" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
}