import gql from "graphql-tag";

/**
 * get homepage by uri
 */
export const GetHomepage = gql`
query GetHomepage($uri: ID!) {
  page(id: $uri, idType: URI) {
    id: databaseId
    title
    url: slug
    blockHeaderHome {
      gallery {
        title
        subtitle
        button {
          title
          url
        }
        image {
          sourceUrl
        }
      }
    }
    blocks {
      ... on AcfAgendaSectionBlock {
        blockEvents {
          text
          button {
            title
            url
          }
        }
      }
      ... on AcfCardsSectionBlock {
        blockCards {
          cards {
            icon {
              sourceUrl
            }
            title
            text
            button {
              title
              url
            }
          }
        }
      }
      ... on AcfContentTextImageBlock {
        blockContentTextImage {
          editor
          highlight
          displayLeft
          image {
            sourceUrl
            altText
            title
          }
          button {
            title
            url
          }
        }
      }
      ... on AcfContentTextBlock {
        blockContentText {
          editor
        }
      }
    }
  }
}
`;