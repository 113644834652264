export default function RadioFilterBlock(props) {
  const { data, title, groupName, handleInputChange } = props;

  if (!data || data.length === 0) return null;

  return (
    <div className="filter">
      <h4 className="mb-3">{title}</h4>
      {data?.map((item, index) => {
        return (
          <div key={item.id} className="form-check">
            <input id={title.toLowerCase() + index} type="radio" name={groupName} className="form-check-input" checked={item.checked} onChange={(e) => handleInputChange(e, index)} data-id={item.id} />
            <label className="form-check-label" htmlFor={title.toLowerCase() + index}>
              {item.name}{item.results && <span> ({item.results?.pageInfo.offsetPagination.total})</span>}
            </label>
          </div>
        )
      })}
    </div>
  );
}